import {
  SET_TOAST_MESSAGE,
  SET_ONBOARDING_STATUS,
  SHOW_INSUFFICIENT_GCOINS_MODAL,
  HIDE_INSUFFICIENT_GCOINS_MODAL,
  SHOW_ACTIVATE_PITCH_MODAL,
  HIDE_ACTIVATE_PITCH_MODAL,
  SHOW_ACTIVATE_INTRO_MODAL,
  HIDE_ACTIVATE_INTRO_MODAL
} from "../CONSTANTS";

const initialState = {
  toastMessage: { message: "", type: "" },
  onboardingStatus: "",
  showInsufficientGCoinsModal: false,
  insufficientGcoinsMode: "",
  insufficientGCoinsCallback: null,
  showActivatePitchModal: false,
  showActivateIntroModal: false,
  requiredAmount: 0,
  ipCountryDetails: {}
};
const globalStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOAST_MESSAGE:
      return { ...state, toastMessage: { ...action.payload } };
    case SET_ONBOARDING_STATUS:
      return { ...state, onboardingStatus: action.payload==='SOCIAL'?'JOB_SEEKER':action.payload };
    case SHOW_INSUFFICIENT_GCOINS_MODAL:
      return {
        ...state,
        showInsufficientGCoinsModal: true,
        insufficientGcoinsMode: action.payload ? action.payload.mode || "JOB_SEEKER" : "JOB_SEEKER",
        requiredAmount: action.payload?.requiredAmount,
        insufficientGCoinsCallback: action.payload.callback,
      };
    case HIDE_INSUFFICIENT_GCOINS_MODAL:
      return {
        ...state,
        showInsufficientGCoinsModal: false,
        requiredAmount: 0,
        insufficientGcoinsMode: "",
        insufficientGCoinsCallback: null
      };
    case SHOW_ACTIVATE_PITCH_MODAL:
      return { ...state, showActivatePitchModal: true };
    case HIDE_ACTIVATE_PITCH_MODAL:
      return { ...state, showActivatePitchModal: false };
    case SHOW_ACTIVATE_INTRO_MODAL:
      return { ...state, showActivateIntroModal: true };
    case HIDE_ACTIVATE_INTRO_MODAL:
      return { ...state, showActivateIntroModal: false };
    case "SET_IP_COUNTRY":
      return { ...state, ipCountryDetails: action.payload };
    default:
      return state;
  }
};
export default globalStateReducer;
