import React, { useEffect } from 'react';
import { showToastMessage } from '@/components/ToastComponent';
import { mainAxiosInstance } from '@/services/networkAdapters/axiosInstance';
import CustomSelectWrapper from '@/components/CustomSelectV2/CustomSelect';
import { addUserDefinedValueToMasterDBApi } from '@/services/utilitiesService';
import Loader from '@/components/Loader';
import styles from '../styles.module.scss';
import step2Styles from './styles.module.scss';
import BackIconSVG from '../../../assets/svg/backIcon.svg';
import AiInterviewBuilderSVG from '../../../assets/svg/AIInterviewBuilder.svg';

const Step2 = ({ 
    goToNextStep, 
    goToPreviousStep, 
    designationData, 
    setDesignationData, 
    aiRecommendedSkills, 
    setAiRecommendedSkills, 
    selectedSkill, 
    setSelectedSkill, 
    setFetchRecommendations, 
    aiRecommendationsFetched 
}) => {

    const fetchAiRecommendedSkills = async () => {
        if (designationData.id && !aiRecommendationsFetched) {
            const response = await mainAxiosInstance(`config/suggested_skills/designation/${designationData.id}`);
            setAiRecommendedSkills(response.data.data);
            setFetchRecommendations(true);
        }
    };

    useEffect(() => {
        const fetchSkillsWithStateUpdate = async () => {
            try {    
                // Wait for the state to update
                await new Promise(resolve => {
                    setTimeout(resolve, 0)
                });
    
                // Call the async function after state updates
                await fetchAiRecommendedSkills();
            } catch (err) {
                showToastMessage("error", "Some Error Occurred!!!");
            }
        };
    
        fetchSkillsWithStateUpdate();
    }, [designationData]);

    const handleAddSkill = (skill) => {
        if (selectedSkill.length < 10) {
            setSelectedSkill((prev) => {
                let flag = false;
                for (let i = 0; i < prev.length; i += 1) {
                    if (skill.id === prev[i].id) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) return [skill, ...prev];
                return prev;
            });

            setAiRecommendedSkills((prev) => prev.filter((skillData) => skillData.id !== skill.id));
        }
    };

    const handleDropdownChange = ([skill]) => {
        console.priorityLog(skill);
        handleAddSkill(skill);
    };

    const handleRemoveSkill = (skill) => {
        setSelectedSkill(prev => prev.filter((data) => data.id !== skill.id));
    }

    return (
        <div className={`${styles.mainContainer} ${styles.smallContainer}`}>
            <div className={step2Styles.TopHeader}>
                <div onClick={goToPreviousStep} className={step2Styles.backSVGDiv}>
                    {/* <img src={BackIconSVG} alt="Previous" /> */}
                </div>
                <div>Let's get started</div>
                <div />
            </div>
            <div className={step2Styles.mainContent}>
                <div className={step2Styles.majorContentDiv}>
                    <div className={step2Styles.DesignationContainer}>
                        <h3>What role are you interviewing for?</h3>
                        <InputDropdown
                            placeHolder="Enter role name (E.g. React developer, Frontend developer, Sales...)"
                            type="designation"
                            value={designationData}
                            onChange={(res) => {
                                setFetchRecommendations(false); // Ensure this state is set first
                                setAiRecommendedSkills([]);
                                setSelectedSkill([]);
                                setDesignationData({ ...res[0], label: res[0].value })
                            }}
                        />
                    </div>
                    {(aiRecommendationsFetched || selectedSkill.length > 0) ? (
                        <div className={step2Styles.displayingSelectedSkills}>
                            <h3>Select up to 10 skills to be evaluated in the interview. <span>{selectedSkill.length}/10</span></h3>
                            <InputDropdown
                                placeHolder="Search skills"
                                type="skill"
                                onChange={handleDropdownChange}
                                disabled={selectedSkill.length >= 10}
                            />
                            <div className={`${step2Styles.SkillDisplayArea} ${aiRecommendedSkills.length === 0 && step2Styles.notSuggested}`}>
                                {selectedSkill.map((skill) => (
                                    <div key={skill.id}>
                                        {skill.value}
                                        <div onClick={() => handleRemoveSkill(skill)}><DeleteSVG /></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : designationData && (
                        <Loader />
                    )}
                </div>
                {aiRecommendedSkills.length !== 0 && (
                    <div className={step2Styles.AiSuggestedSkills}>
                        <h3><img src={AiInterviewBuilderSVG} alt='' />AI Suggested Skills</h3>
                        <div className={step2Styles.AiRecommendedSkillGrp}>
                            {aiRecommendedSkills.map((skill) => (
                                <div key={skill.id} onClick={() => handleAddSkill(skill)}>
                                    <div><AddIcon /></div>
                                    {skill.value.length > 20 ? `${skill.value.slice(0, 20)}...` : skill.value}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className={step2Styles.buttonGrp}>
                    <button type="button" onClick={goToNextStep} disabled={selectedSkill.length === 0}>Continue</button>
                </div>
            </div>
        </div>
    );
};

export default Step2;

const InputDropdown = ({
    placeHolder,
    type,
    onChange,
    isError = false,
    searchType,
    disabled,
    value
  }) => {
    const handleInputChange = (option, type, e) => {
        if (e && e.__isNew__) {
            addUserDefinedValueToMasterDBApi({
              value: e.label,
              master: type,
            })
              .then((res) => {
                if (res.status === 200) {
                  const d = {
                    id: res.data.data.id,
                    value: res.data.data.value,
                  };
                  console.log(d);
                  showToastMessage(
                    "info",
                    `${type} added successfully.`
                  );
                  onChange([d], type);
                }
              })
              .catch((err) => {
                showToastMessage(
                  "error",
                  err.response.data.message
                );
              });
        } else {
            onChange(option, type);
        }
    };
    return (
        <div className={styles.inputBoxContainer} style={{ marginBottom: '10px' }}>
            <CustomSelectWrapper
                theme={isError ? "errorSeekerOnboarding" : "SeekerOnboarding"}
                type={type}
                className={styles.selectBox}
                placeholder={placeHolder}
                value={value}
                async
                readOnly={disabled}
                searchType={searchType || null}
                allowNewOptions
                onChange={(e) =>
                    handleInputChange(
                        [
                        {
                            id: e.value,
                            value: e.label,
                        }
                        ],
                        type,
                        e
                    )
                }
            />
        </div>
    );
};

const DeleteSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path d="M13.8333 3.33203C14.0101 3.33203 14.1797 3.40227 14.3047 3.52729C14.4298 3.65232 14.5 3.82189 14.5 3.9987C14.5 4.17551 14.4298 4.34508 14.3047 4.4701C14.1797 4.59513 14.0101 4.66536 13.8333 4.66536H13.1667L13.1647 4.7127L12.5427 13.4267C12.5187 13.7631 12.3682 14.0779 12.1214 14.3077C11.8746 14.5376 11.5499 14.6654 11.2127 14.6654H5.78667C5.44943 14.6654 5.12471 14.5376 4.87792 14.3077C4.63114 14.0779 4.48061 13.7631 4.45667 13.4267L3.83467 4.71336L3.83333 4.66536H3.16667C2.98986 4.66536 2.82029 4.59513 2.69526 4.4701C2.57024 4.34508 2.5 4.17551 2.5 3.9987C2.5 3.82189 2.57024 3.65232 2.69526 3.52729C2.82029 3.40227 2.98986 3.33203 3.16667 3.33203H13.8333ZM11.8313 4.66536H5.16867L5.78733 13.332H11.2127L11.8313 4.66536ZM9.83333 1.33203C10.0101 1.33203 10.1797 1.40227 10.3047 1.52729C10.4298 1.65232 10.5 1.82189 10.5 1.9987C10.5 2.17551 10.4298 2.34508 10.3047 2.4701C10.1797 2.59513 10.0101 2.66536 9.83333 2.66536H7.16667C6.98986 2.66536 6.82029 2.59513 6.69526 2.4701C6.57024 2.34508 6.5 2.17551 6.5 1.9987C6.5 1.82189 6.57024 1.65232 6.69526 1.52729C6.82029 1.40227 6.98986 1.33203 7.16667 1.33203H9.83333Z" fill="#FF9B9B"/>
    </svg>
)

const AddIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M6.50003 1.75736V10.2426M2.25739 6H10.7427" stroke="#2369AC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)