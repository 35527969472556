/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { GrDrag } from "react-icons/gr";
import styles from './styles.module.scss';

const DragAndDropFunc =forwardRef(({ questions, onUpdateQuestions, onInputChange }, ref) => {
  const [items, setItems] = useState([]);
  const [localValues, setLocalValues] = useState({});

  // Initialize items and localValues
  useEffect(() => {
    setItems(questions);
    const initialValues = {};
    questions.forEach((q) => {
      initialValues[q.id] = q.question;
    });
    setLocalValues(initialValues);
  }, [questions]);

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    setItems(reorderedItems);
    onUpdateQuestions(reorderedItems); // Update questions in parent component
  };

  const handleLocalInputChange = (id, value) => {
    setLocalValues((prev) => ({
      ...prev,
      [id]: value,
    }));
    onInputChange(id, value); // Only debounce the update to the parent state
  };

  useEffect(() => {
    setItems(questions);
  }, [questions]);

  const handleRemoveQuestions = (data) => {
    const newQuestionsArr = items.filter((item) => item.id !== data.id);
    if(newQuestionsArr.length === 0) {
      newQuestionsArr.push({
        id: `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`,
        question: "",
        isRequired: false,
      })
    }
    setItems(newQuestionsArr);
    onUpdateQuestions(newQuestionsArr);
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided1) => (
          <div
            {...provided1.droppableProps}
            ref={(el) => {
              provided1.innerRef(el);
              if (ref) ref.current = el; // Attach ref from parent
            }}
            className={styles.draggableContainer}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={styles.DragAbleSkillDiv}
                  >
                    <div className={styles.displayTop}>
                      Question {index+1}
                      <div
                          {...provided.dragHandleProps}
                      >
                          <GrDrag />
                      </div>
                    </div>
                    <div className={styles.inputDiv}>
                      <input type='text' placeholder='Question ?' required={index === 0} value={localValues[item.id] || ""} onChange={(e) => handleLocalInputChange(item.id, e.target.value)} />
                    </div>
                    <div className={styles.bottomBar}>
                      <div>
                        <input 
                          type="checkbox" 
                          hidden="hidden" 
                          id={item.id} 
                          checked={item.isRequired}
                          className={styles.inputSwitchField}
                          onChange={(e) =>
                            onUpdateQuestions(
                              items.map((q) =>
                                q.id === item.id ? { ...q, isRequired: e.target.checked } : q
                              )
                            )
                          }
                        />
                        <label className={styles.switch} htmlFor={item.id} />
                        <label htmlFor={item.id}>Required</label>
                      </div>
                      <div onClick={() => handleRemoveQuestions(item)}>
                        <DeleteSVG />
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided1.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DragAndDropFunc;

const DeleteSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path d="M13.8333 3.33203C14.0101 3.33203 14.1797 3.40227 14.3047 3.52729C14.4298 3.65232 14.5 3.82189 14.5 3.9987C14.5 4.17551 14.4298 4.34508 14.3047 4.4701C14.1797 4.59513 14.0101 4.66536 13.8333 4.66536H13.1667L13.1647 4.7127L12.5427 13.4267C12.5187 13.7631 12.3682 14.0779 12.1214 14.3077C11.8746 14.5376 11.5499 14.6654 11.2127 14.6654H5.78667C5.44943 14.6654 5.12471 14.5376 4.87792 14.3077C4.63114 14.0779 4.48061 13.7631 4.45667 13.4267L3.83467 4.71336L3.83333 4.66536H3.16667C2.98986 4.66536 2.82029 4.59513 2.69526 4.4701C2.57024 4.34508 2.5 4.17551 2.5 3.9987C2.5 3.82189 2.57024 3.65232 2.69526 3.52729C2.82029 3.40227 2.98986 3.33203 3.16667 3.33203H13.8333ZM11.8313 4.66536H5.16867L5.78733 13.332H11.2127L11.8313 4.66536ZM9.83333 1.33203C10.0101 1.33203 10.1797 1.40227 10.3047 1.52729C10.4298 1.65232 10.5 1.82189 10.5 1.9987C10.5 2.17551 10.4298 2.34508 10.3047 2.4701C10.1797 2.59513 10.0101 2.66536 9.83333 2.66536H7.16667C6.98986 2.66536 6.82029 2.59513 6.69526 2.4701C6.57024 2.34508 6.5 2.17551 6.5 1.9987C6.5 1.82189 6.57024 1.65232 6.69526 1.52729C6.82029 1.40227 6.98986 1.33203 7.16667 1.33203H9.83333Z" fill="#FF9B9B"/>
  </svg>
)