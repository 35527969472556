import React from 'react'
import NavBar from '../AutoRecruiterLandingPage/Navbar/Navbar';

import FeatureSVG from './assets/svg/Features.svg';

import styles from './styles.module.scss';

const AiInterviewLandingPage = () => {

    const tabs = [
        { title: "Video Interviews", icon: "" },
        { title: "Cheat Detection", icon: "" },
        { title: "Multi-Language", icon: "" },
        { title: "Detailed Insights", icon: "" },
        { title: "Interview Summaries", icon: "" }
    ]

    return (
        <div>
            <NavBar hirerMode={1} />
            <div className={styles.AiInterviewerLandingPage}>
                <div className={styles.heroSection}>
                    <div className={styles.textDiv}>
                        <h2>Personalize Your Hiring with <br /><span>AI-Powered Interviews</span></h2>
                        <p>Set up interviews, gain deep candidate insights, and make confident hiring decisions all powered by AI</p>
                    </div>
                    <div className={styles.heroSectionBottom}>
                        <div>Get Started Your Hiring</div>
                    </div>
                </div>
                <div className={styles.featureDisplay}>
                    <div><img src={FeatureSVG} alt='' /> Features</div>
                    <h2>Powerful Features, <span>Smarter Hiring</span></h2>
                    <p>Explore the cutting-edge tools that make AI Interviewer your ultimate recruitment partner</p>
                    <div />
                </div>
            </div>
        </div>
    )
}
export default AiInterviewLandingPage