import React, { useEffect, useRef, useState } from 'react'
import { Link , useHistory } from 'react-router-dom';

import Navbar from '@/dashboards/Navbar';
import ToggleNavbar from '@/dashboards/Navbar/ToggleNavbar/ToggleNavbar';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mainAxiosInstance } from '@/services/networkAdapters/axiosInstance';
import { showToastMessage } from '@/components/ToastComponent';
import { CircularProgress } from '@mui/material';
// import Loader from '@/components/LoaderLg';
import Loader from '@/pages/InterviewPage/NewInterviewExperience/Loader/Loader';
import Tabs from './constants/Tabs';

import styles from './styles.module.scss';
import NoInterviewAvailable from './components/NoInterviewAvailable';


const AiInterviewListViewer = () => {
    const [activeTab, setActiveTab] = useState(Tabs.ACTIVE);

    const [data, setData] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [page, setPage] = useState(0);
    const history = useHistory();

    const [fetchLoadingNextPageData, setFetchLoadingNextPageData] = useState(false);
    const lastCardRef = useRef(null);
    const [hasMoreData, setHasMoreData] = useState(true);

    const breadCrumbsData = [
        { text: "Dashboard", url: "/" },
        { text: "Interview Dashboard" }
    ]

    function timeAgo(date) {
        const now = new Date();
        const pastDate = new Date(date);
    
        const diffInMs = now - pastDate; // Difference in milliseconds
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24)); // Days difference
        const diffInMonths = Math.floor(diffInDays / 30); // Approx months
        const diffInYears = Math.floor(diffInMonths / 12); // Approx years
    
        if (diffInYears > 0) {
            return diffInYears === 1 
                ? "Created 1 year ago" 
                : `Created ${diffInYears} years ago`;
        } 
        if (diffInMonths > 0) {
            return diffInMonths === 1 
                ? "Created 1 month ago" 
                : `Created ${diffInMonths} months ago`;
        } 
        if (diffInDays > 0) {
            return diffInDays === 1 
                ? "Created 1 day ago" 
                : `Created ${diffInDays} days ago`;
        }
        return "Created today";
    }

    const getData = async () => {
        const res = await mainAxiosInstance.get(
            `hirer/interview_posts_by_type?limit=10&page=0&type=${activeTab}`
        );
        // 
        // console.log(res?.data?.data);
        setData(res?.data?.data);
        setDataFetched(true);
    }

    
    const updateData = async () => {
        try {
          const res = await mainAxiosInstance.get(
            `hirer/interview_posts_by_type?limit=10&page=${page}&type=${activeTab}`
          );
    
          const newData = res?.data?.data || [];
          if (newData.length === 0) {
            // If no data returned, set hasMoreData to false
            setHasMoreData(false);
          } else {
            setDataFetched(true);
            setData((prev) => [...prev, ...newData]);
          }
          setFetchLoadingNextPageData(false);
        } catch (err) {
          console.error('Error fetching data:', err);
          setFetchLoadingNextPageData(false);
        }
    };

    useEffect(() => {
        getData();
    }, [activeTab]);

    useEffect(() => {
        if(dataFetched && activeTab === Tabs.ACTIVE && parseInt(page, 10)===0 && data.length === 0) {
            history.push("/hirer/create-interview/1");
        }
    }, [data]);

    const handleCopy = (textToCopy) => {
        navigator.clipboard
            .writeText(textToCopy)
                .then(() => {
                    showToastMessage("info", "Interview Link copied successfully!!");
                })
                .catch(() => {
                    showToastMessage("error", "Failed to copy text: ");
                });
    };


    const handleObserver = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !fetchLoadingNextPageData && hasMoreData) {
            setFetchLoadingNextPageData(true);
            setPage((prevPage) => prevPage + 1);
        }
    };
    
    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, { threshold: 0.1 });
    
        const lastElement = lastCardRef.current;
        if (lastElement) {
            observer.observe(lastElement);
        }
    
        return () => {
            if (lastElement) {
                observer.unobserve(lastElement);
            }
        };
    }, [lastCardRef.current, data]);
    
    useEffect(() => {
        if (page > 0) {
            updateData();
        }
    }, [page]);

    const fetchData = async (type) => {
        const res = await mainAxiosInstance.get(
            `hirer/interview_posts_by_type?limit=10&page=0&type=${type}`
        );
        setData(res?.data?.data);
        setDataFetched(true);
    }

    const updateTab = (tab) => {
        if (tab === Tabs.ACTIVE) {
            setDataFetched(false);
            setHasMoreData(true);
            setActiveTab(prev => {
                if(prev === tab) {
                    fetchData(tab);
                    return tab;
                } 
                return tab;
            }); 
            setPage(0); 
        } else {
            setHasMoreData(true);
            setActiveTab(tab);
            setPage(0);
        }
    };

    return (
        <div className={styles.AiInterviewerDashboard}>
            <Navbar
                text="Manage Interview"
                additionalStyles={{
                    backgroundColor: "#fff"
                }}
                showNewUserModeSwitch
            />
            <div className={styles.mainContainer}>
                <ToggleNavbar isExpanded />
                <div className={styles.leftContainer}>
                    <div className={styles.aiInterviewDashboardMainDiv}>
                        <Breadcrumbs items={breadCrumbsData} />
                        <div className={styles.mainContentDivHeader}>
                            <div className={styles.TopHeader}>
                                <h3>AI Interviewer</h3>
                                <div className={styles.TabSwitch}>
                                    <div onClick={() => updateTab(Tabs.ACTIVE)} className={activeTab === Tabs.ACTIVE && styles.active}>Active</div>
                                    <div onClick={() => updateTab(Tabs.INACTIVE)} className={activeTab === Tabs.INACTIVE && styles.active}>Inactive</div>
                                    <div onClick={() => updateTab(Tabs.PENDING)} className={activeTab === Tabs.PENDING && styles.active}>Pending</div>
                                </div>
                            </div>
                            <Link to="/hirer/create-interview/1" className={styles.CreateInterview}>
                                <PlusSVG color='#FFF' />
                                <div>
                                    Create a interview
                                </div>
                            </Link>
                        </div>
                        { !dataFetched ? (
                            <Loader />
                        ) : data.length > 0 ? (
                            <>
                                <div className={styles.cardDiv}>
                                    {data.map((a, index) => (
                                        <div className={styles.card} key={a.offerId} ref={index === data.length - 1 ? lastCardRef : null}>
                                            <div className={styles.title}>{a.title}</div>
                                            <div className={styles.createdOn}>
                                                {timeAgo(a.postedAt)}
                                            </div>
                                            {/* <div className={styles.qualifyingPercentage}><span>{(a.Qualified/a.invited)*100}%</span> Qualifying</div> */}
                                            { activeTab !== Tabs.PENDING ? (
                                                <>  
                                                    <div className={styles.addOnsPlusQualifying}>
                                                        <div className={styles.qualifyingPercentage}><span>{a?.assessmentAnalytics?.qualifiedPercentage || 0}%</span> Qualifying</div>
                                                        <div className={styles.addOns}>
                                                            { a.addons.map((addon) => (
                                                                <div className={styles.addOn} key={addon.type_selection_id}>{addon.display_name}</div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className={styles.progressContainer}>
                                                        <div className={`${styles.circle} ${styles.completed}`}>
                                                            <div className={styles.info}><span>{a?.invitedCandidates || 0}/{a?.totalInvitesAvailable || 0}</span> invited</div>
                                                        </div>
                                                        <div className={styles.line} />
                                                        <div className={`${styles.circle} ${styles.Inprogress}`}>
                                                            <div className={styles.info}><span>{a?.assessmentAnalytics?.interviewedCount || 0}</span> Interviewed</div>
                                                        </div>
                                                        <div className={styles.line} />
                                                        <div className={styles.circle}>
                                                            <div className={styles.info}><span>{a?.assessmentAnalytics?.qualifiedCount || 0}</span> Qualified</div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className={styles.addOns}>
                                                    { a.addons.map((addon) => (
                                                        <div className={styles.addOn} key={addon.type_selection_id}>{addon.display_name}</div>
                                                    ))}
                                                </div>
                                            )}
                                            <div className={styles.ButtonGrp}>
                                                { activeTab === Tabs.ACTIVE ? (
                                                    <div className={styles.activeTab}>
                                                        <div className={styles.copyInterviewLink} onClick={() => handleCopy(a.interviewLink)}><CopySVG /> Copy interview link</div>
                                                        <Link to={`/manage-interviews/${a.offerId}`} className={styles.activateButton}>View Assessments</Link>
                                                    </div>
                                                ) : activeTab === Tabs.INACTIVE ? (
                                                    <>
                                                        <Link to={`/manage-interviews/${a.offerId}`}  className={styles.assessmentButton}>View Assessments</Link>
                                                        <Link to={`/hirer/create-interview/2?offerId=${a.offerId}`} className={styles.activateButton}>Reactivate Interview</Link>
                                                    </>
                                                ) : (
                                                    <div className={styles.activeTab}>
                                                        <div className={styles.showData}>This Interview is on-hold</div>
                                                        <Link to={`/hirer/create-interview/4?offerId=${a.offerId}`} className={styles.pendingButton}><AlertIcon /> Complete Payment</Link>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {fetchLoadingNextPageData && hasMoreData && 
                                    <div style={{ textAlign: 'center' }}>
                                        <CircularProgress />
                                    </div>
                                }
                            </>
                        ) : (
                            <NoInterviewAvailable />
                            // <div className={styles.FallBackSection}>
                            //     <h3>No {activeTab === Tabs.INACTIVE ? "Inactive" : "Pending"} Interviews yet </h3>
                            //     <p>Set up an interview and get detailed candidate insights for confident decisions.</p>
                            //     <Link to="/hirer/create-interview" className={styles.CreateInterview}>
                            //         <PlusSVG color='#FFF' />
                            //         <div>
                            //             Create a interview
                            //         </div>
                            //     </Link>
                            // </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AiInterviewListViewer

const PlusSVG = ({ color="#4B5563" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill={color} >
        <path d="M17.2143 11.2857H10.7857V17.7143C10.7857 18.0553 10.6503 18.3823 10.4091 18.6234C10.168 18.8645 9.84099 19 9.5 19C9.15901 19 8.83198 18.8645 8.59086 18.6234C8.34974 18.3823 8.21429 18.0553 8.21429 17.7143V11.2857H1.78571C1.44472 11.2857 1.1177 11.1503 0.876577 10.9091C0.635459 10.668 0.5 10.341 0.5 10C0.5 9.65901 0.635459 9.33198 0.876577 9.09086C1.1177 8.84975 1.44472 8.71429 1.78571 8.71429H8.21429V2.28571C8.21429 1.94472 8.34974 1.61769 8.59086 1.37658C8.83198 1.13546 9.15901 1 9.5 1C9.84099 1 10.168 1.13546 10.4091 1.37658C10.6503 1.61769 10.7857 1.94472 10.7857 2.28571V8.71429H17.2143C17.5553 8.71429 17.8823 8.84975 18.1234 9.09086C18.3645 9.33198 18.5 9.65901 18.5 10C18.5 10.341 18.3645 10.668 18.1234 10.9091C17.8823 11.1503 17.5553 11.2857 17.2143 11.2857Z" stroke="white"/>
    </svg>
)


const AlertIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g clipPath="url(#clip0_3340_13427)">
      <path d="M9.4327 3.53681L1.66854 16.4985C1.50846 16.7757 1.42376 17.09 1.42286 17.4101C1.42196 17.7302 1.5049 18.045 1.66343 18.3231C1.82195 18.6012 2.05053 18.833 2.32643 18.9953C2.60233 19.1577 2.91593 19.245 3.23604 19.2485H18.7644C19.0845 19.245 19.3981 19.1577 19.674 18.9953C19.9499 18.833 20.1785 18.6012 20.337 18.3231C20.4955 18.045 20.5784 17.7302 20.5775 17.4101C20.5766 17.09 20.4919 16.7757 20.3319 16.4985L12.5677 3.53681C12.4043 3.26741 12.1742 3.04467 11.8996 2.89009C11.6251 2.73551 11.3153 2.6543 11.0002 2.6543C10.6851 2.6543 10.3753 2.73551 10.1008 2.89009C9.82621 3.04467 9.59612 3.26741 9.4327 3.53681V3.53681Z" stroke="#DE303F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11 15.5835H11.0075" stroke="#DE303F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11 8.25V11.9167" stroke="#DE303F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_3340_13427">
        <rect width="22" height="22" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

const CopySVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.33337 7.77838C1.33337 7.17723 1.57218 6.60069 1.99727 6.17561C2.42235 5.75053 2.99888 5.51172 3.60004 5.51172H5.07004V6.84505H3.60004C3.35251 6.84505 3.11511 6.94339 2.94007 7.11842C2.76504 7.29345 2.66671 7.53085 2.66671 7.77838V12.5784C2.66671 12.8259 2.76504 13.0633 2.94007 13.2384C3.11511 13.4134 3.35251 13.5117 3.60004 13.5117H8.40004C8.64758 13.5117 8.88497 13.4134 9.06001 13.2384C9.23504 13.0633 9.33337 12.8259 9.33337 12.5784V11.3784H10.6667V12.5784C10.6667 13.1795 10.4279 13.7561 10.0028 14.1812C9.57773 14.6062 9.0012 14.8451 8.40004 14.8451H3.60004C2.99888 14.8451 2.42235 14.6062 1.99727 14.1812C1.57218 13.7561 1.33337 13.1795 1.33337 12.5784V7.77838Z" fill="#2369AC"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.66667 2H12C12.7072 2 13.3855 2.28095 13.8856 2.78105C14.3857 3.28115 14.6667 3.95942 14.6667 4.66667V10C14.6667 10.7072 14.3857 11.3855 13.8856 11.8856C13.3855 12.3857 12.7072 12.6667 12 12.6667H6.66667C5.95942 12.6667 5.28115 12.3857 4.78105 11.8856C4.28095 11.3855 4 10.7072 4 10V4.66667C4 3.95942 4.28095 3.28115 4.78105 2.78105C5.28115 2.28095 5.95942 2 6.66667 2ZM6.66667 3.33333C6.31304 3.33333 5.97391 3.47381 5.72386 3.72386C5.47381 3.97391 5.33333 4.31304 5.33333 4.66667V10C5.33333 10.3536 5.47381 10.6928 5.72386 10.9428C5.97391 11.1929 6.31304 11.3333 6.66667 11.3333H12C12.3536 11.3333 12.6928 11.1929 12.9428 10.9428C13.1929 10.6928 13.3333 10.3536 13.3333 10V4.66667C13.3333 4.31304 13.1929 3.97391 12.9428 3.72386C12.6928 3.47381 12.3536 3.33333 12 3.33333H6.66667Z" fill="#2369AC"/>
    </svg>
)