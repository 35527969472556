import React from 'react'
import styles from './styles.module.scss';

import LiveVideoInterviewSVG from '../svg/LiveVideoInterview.svg';
import AIPoweredScreeningSVG from '../svg/AIPoweredScreening.svg';
import CheatDetectionSVG from '../svg/CheatDetection.svg';
import DetailedActionableInsightsSVG from '../svg/DetailedActionableInsights.svg';

const NoInterviewAvailable = () => {
  
    const Step1Data = [
        { title: "Live Video Interviews", desc: "Conducts live video interviews, evaluating a candidate’s qualifications, experience, and skills for a complete profile.", icon: LiveVideoInterviewSVG },
        { title: "AI-Powered Screening with Domain Expertise", desc: "Delivers industry-specific assessments across IT, healthcare, finance, and more for accurate evaluations.", icon: AIPoweredScreeningSVG },
        { title: "Cheat Detection", desc: "Detects use of external tools, like ChatGPT, during interviews to ensure genuine assessments.", icon: CheatDetectionSVG },
        { title: "Detailed, Actionable Insights", desc: "Provides comprehensive reports with insights on candidate fit and qualifications, supporting informed hiring decisions.", icon: DetailedActionableInsightsSVG }
    ]

    return (
        <div className={`${styles.mainContainer} ${styles.mainCont}`}>
            <div className={styles.step1Div}>
                <div className={styles.header}>
                    <h1>Goodspace AI Interviewer</h1>
                    <div>Set up an interview and get detailed candidate insights for confident hiring.</div>
                </div>
                <div className={styles.IntroInfoGrp}>
                    { Step1Data.map((data) => (
                        <div key={data.title} className={styles.IntroInfo}>
                            <h4><img src={data.icon} alt={data.title} /> <div>{data.title}</div></h4>
                            <div>{data.desc}</div>
                        </div>
                    ))}
                </div>
                <div className={styles.buttonGrp}>
                    {/* <button type='button' onClick={goToNextStep}>Create a interview</button> */}
                </div>
            </div>
        </div>
    )
}

export default NoInterviewAvailable