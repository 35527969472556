import React, { useState, useRef } from 'react';
import styles from '../styles.module.scss';

const Message = ({ messages }) => {
    const [isPlaying, setIsPlaying] = useState(null); // Track the currently playing message
    const audioRefs = useRef({}); // Store references to all audio elements

    const handleAudioPlay = (audioKey) => {
        // Stop all other audio players
        Object.keys(audioRefs.current).forEach((key) => {
            if (key !== audioKey && audioRefs.current[key]) {
                audioRefs.current[key].pause();
                audioRefs.current[key].currentTime = 0; // Reset playback
            }
        });

        // Toggle play/pause for the clicked audio
        if (audioRefs.current[audioKey]) {
            if (isPlaying === audioKey) {
                audioRefs.current[audioKey].pause();
                setIsPlaying(null);
            } else {
                audioRefs.current[audioKey].play();
                setIsPlaying(audioKey);
            }
        }
    };

    return (
        <>
            {messages.map((message, index) => (
                <div
                    // key={index}
                    className={message.sentBy === 'HR' ? styles.aiMsg : styles.userMsg}
                >
                    {message.message}
                    {message.messageAudio && (
                        <div style={{ marginTop: '.8em' }}>
                            <div
                                style={{
                                    width: 'fit-content',
                                    display: 'flex',
                                    gap: '10px',
                                    alignItems: 'center',
                                }}
                                onClick={() => handleAudioPlay(index)}
                            >
                                {isPlaying === index ? (
                                    <div className={`${styles.audioWrapper} ${styles.random}`}>
                                        <div className={`${styles.bar} ${styles.bar1}`} />
                                        <div className={`${styles.bar} ${styles.bar2}`} />
                                        <div className={`${styles.bar} ${styles.bar3}`} />
                                        <div className={`${styles.bar} ${styles.bar4}`} />
                                        <div className={`${styles.bar} ${styles.bar5}`} />
                                    </div>
                                ) : (
                                    <SpeakerSvg />
                                )}
                            </div>
                            <audio
                                src={message.messageAudio?.link}
                                ref={(el) => (audioRefs.current[index] = el)} // Store audio refs dynamically
                                onEnded={() => setIsPlaying(null)} // Reset playing state on end
                                hidden
                            />
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};

const SpeakerSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 12 11" fill="none">
        <g clipPath="url(#clip0_4025_1486)">
            <path
                d="M1.875 4.12505V6.87505H3.70833L6 9.16672V1.83339L3.70833 4.12505H1.875ZM8.0625 5.50005C8.0625 4.6888 7.595 3.99214 6.91667 3.65297V7.34255C7.595 7.00797 8.0625 6.3113 8.0625 5.50005ZM6.91667 1.48047V2.42464C8.24125 2.8188 9.20833 4.04714 9.20833 5.50005C9.20833 6.95297 8.24125 8.1813 6.91667 8.57547V9.51964C8.75458 9.10255 10.125 7.46172 10.125 5.50005C10.125 3.53839 8.75458 1.89755 6.91667 1.48047Z"
                fill="#2369AC"
            />
        </g>
        <defs>
            <clipPath id="clip0_4025_1486">
                <rect width="11" height="11" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
);

export default Message;
