/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactModal from "react-modal";
import {
  loginApi,
  verifyOtpApi,
  resendOtpApi
} from "@/services/userAuthService";
import PhoneNumberInput from "@/components/PhoneNumberInput/PhoneNumberInput";
import { showToastMessage } from "@/components/ToastComponent";
import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import styles from "./NotLoggedInModal.module.scss";

function maskCharacter(str, mask, n = 1) {
  return `${str}`.slice(0, -n).replace(/./g, mask) + `${str}`.slice(-n);
}
export default function LoginModal({ isOpen, hideModal, onLoginComplete, onPushedToOnboarding, doNotRedirect, redirectToHome }) {

  // OTP length
  const length = 4;
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(-1);
  const [otpSentStage, setOtpSentStage] = useState(0);
  const history = useHistory();
  const [numData, setNumData] = useState({
    number: "",
    isoCode: "IN",
    countryCode: "+91",
    otp: ""
  });

  const resetOtpToNull = () => {
    console.log("Resetting OTP to null");
    const nullArray = new Array(otp.length).fill("");
    setOtp([...nullArray]);
    inputRefs.current[0].focus();
    console.log("OTP after reset:", otp);
  };


  const hideModalLocal = () => {
    setLoading(false);
    setOtpSentStage(0);
    setNumData({
      number: "",
      isoCode: "IN",
      countryCode: "+91",
      otp: ""
    });
    hideModal();
  };
  const startTimer = (time) => {
    setTimer(time);
  };
  useEffect(() => {
    let timeout;
    if (timer > 0)
      timeout = setTimeout(() => {
        startTimer(timer - 1);
      }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [timer]);
  const initLogin = async () => {
    try {
      console.log(numData.number, numData.countryCode);
      const phoneNumber = `${numData.countryCode}${numData.number}`;
      console.log(phoneNumber);
      const parsedNumber = parsePhoneNumber(phoneNumber);
      console.log(isValidPhoneNumber(phoneNumber));
      if (
        !isValidPhoneNumber(phoneNumber) ||
        parsedNumber.nationalNumber !== numData.number
      ) {
        showToastMessage("error", "This phone number is invalid.");
        return;
      }
      console.log(isPossiblePhoneNumber(phoneNumber));
      setLoading(true);
      const res = await loginApi(numData.number, numData.countryCode);
      if (res.status === 200 || res.status === 201) {
        // setHandleShow(true);
        startTimer(15);
        setOtpSentStage(1);
      }
    } catch (err) {
      if (err.message === "Request failed with status code 404") {
        sessionStorage.setItem("phoneNum", JSON.stringify(numData));
        history.push("/signup");
      }
    } finally {
      setLoading(false);
    }
  };
  const verifyOtp = async (otp) => {
    try {
      setLoading(true);

      if (otp.length !== 4) {
        showToastMessage("error", "OTP is invalid.");
        resetOtpToNull();
        return;
      }
      const res = await verifyOtpApi(numData.number, otp);
      if (res.status === 201 || res.status === 200) {
        showToastMessage("info", res.data.message);
        if (res.status === 201 || res.status === 200) {
          showToastMessage(res.data.message);
          console.log(res.data);
          if (redirectToHome) {

            history.push(`/`);
          }
          if (!doNotRedirect && res.data.data.status === 2) {
            if (onPushedToOnboarding) onPushedToOnboarding();
            history.push(`/onboarding`);
          } else if(doNotRedirect && res.data.data.status === 2) {
            onPushedToOnboarding();
          } else {
            if (onLoginComplete) onLoginComplete();
            hideModalLocal();
          }
        }
      }
    } catch (err) {
      console.log(err);
      showToastMessage("error", err.response.data.message);
      resetOtpToNull();
    } finally {
      setLoading(false);
    }
  };
  const resendOtp = async () => {
    try {
      if (timer > 0 || otpSentStage === 3) return;
      const res = await resendOtpApi(numData.number, numData.countryCode);
      if (res.status === 201 || res.status === 200) {
        showToastMessage("info", res.data.message);

        if (otpSentStage === 1) {
          setOtpSentStage(2);
          startTimer(30);
        }
        if (otpSentStage === 2) {
          setOtpSentStage(3);
        }
      }
    } catch (err) {
      showToastMessage("error", err.response.data.message);
    }
  };
  const onMyKeyPress = (e) => {
    console.log(e.key);
    if (!e.key || e.key === "Enter") {
      e.preventDefault();
      if (otpSentStage === 0) initLogin();
      else verifyOtp();
      setTimeout(() => {
        inputRefs.current[0].focus();
      }, 500);
    }
  };

  // OTP
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const {value} = e.target;
    if (Number.isNaN(value)) return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) verifyOtp(combinedOtp);

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={styles.overlay}
      className={styles.modal}
      onRequestClose={() => {
        hideModalLocal();
        resetOtpToNull();
      }}
    >
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.title}>Login or Register</div>
        <div className={styles.text}>
          {otpSentStage === 0 ? (
            "Enter mobile number to continue"
          ) : (
            <>
              {`Enter One Time Password sent to ${numData.countryCode
                }${maskCharacter(numData.number, "x", 2)} `}
              <span
                className={styles.change}
                onClick={() => setOtpSentStage(0)}
              >
                (Change)
              </span>
            </>
          )}
        </div>
        <div className={styles.inputContainer}>
          {otpSentStage === 0 ? (
            <PhoneNumberInput
              countryCode={numData.countryCode}
              widthFull
              onChangeCountryCode={(a) =>
                setNumData((b) => ({
                  ...b,
                  isoCode: a.isoCode,
                  countryCode: a.dialCode
                }))
              }
              isoCode={numData.isoCode}
              phoneNumber={numData.number}
              onChangePhoneNumber={(a) => {
                setNumData((b) => ({ ...b, number: a }));
              }}
              login={(e) => onMyKeyPress(e, "login")}
            />
          ) : (
            <div className={styles.otpContainer}>
              {otp.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  ref={(input) => (inputRefs.current[index] = input)}
                  value={value}
                  onChange={(e) => handleChange(index, e)}
                  onClick={() => handleClick(index)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  className={styles.otpInput}
                />
              ))}
            </div>
          )}
        </div>
        <div className={styles.buttons}>
          {otpSentStage === 0 || otpSentStage === 3 ? (
            <div />
          ) : (
            <div
              className={styles.buttonResend}
              onClick={resendOtp}
              style={{ cursor: timer > 0 ? "default" : "pointer" }}
            >
              {timer > 0 ? `` : "Resend OTP"}
            </div>
          )}
          <button className={styles.buttonContinue} onClick={onMyKeyPress}>
            {!loading ? "Continue" : <Spinner />}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
const Logo = () => (
  <svg
    width="32"
    height="48"
    viewBox="0 0 32 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.424755 37.3884C0.339897 37.8959 0.255039 38.6572 0.679329 39.8414C2.03706 43.4785 6.36481 45.5931 7.29825 46.016C10.8623 47.7077 14.0869 47.7923 15.5295 47.7923C16.2083 47.7923 18.9238 47.7923 22.3181 46.6927C23.591 46.2698 25.4579 45.6777 27.4096 44.2398C29.2765 42.8864 30.0402 41.6177 30.2948 41.2793C30.2948 41.2793 30.2948 41.2793 30.2948 41.1947C30.5493 40.7718 31.3979 39.4185 31.1433 37.7268C31.1433 37.473 30.8039 35.8659 29.4462 34.8509C29.4462 34.8509 27.155 33.3284 24.6093 34.6818C24.5244 34.6818 24.4396 34.7663 24.4396 34.7663C24.2698 34.9355 24.1001 35.0201 24.1001 35.0201C23.8456 35.2738 22.9121 36.1197 22.8273 36.2043C22.8273 36.2043 22.2333 36.7118 20.7058 37.7268C20.5361 37.8114 20.3664 37.9805 20.3664 37.9805L20.2815 38.0651C19.8572 38.3189 19.0087 38.7418 17.9904 38.9955C17.2266 39.1647 15.6143 39.6722 13.5777 39.1647C11.8806 38.8264 10.6926 37.9805 10.1834 37.6422C9.4197 37.1347 9.33484 36.8809 7.63768 35.5276C6.36481 34.5126 6.11024 34.3434 5.68595 34.2588C4.15851 33.7513 2.71592 34.5126 2.5462 34.6818C2.37649 34.5972 0.764187 35.5276 0.424755 37.3884Z"
      fill="#216CCF"
    />
    <path
      d="M30.2943 15.3961C30.2943 11.3361 30.3792 7.19148 30.3792 3.13144C30.2943 2.7931 30.1246 2.37018 29.7003 1.86267C29.4457 1.43975 28.7669 0.593911 27.5788 0.17099C27.3243 0.0864057 26.3908 -0.167347 25.3725 0.17099C24.0148 0.593911 23.4208 1.77809 23.2511 2.03184C22.9965 2.45476 22.9117 2.87768 22.9117 3.13144C22.9117 3.3006 22.9117 3.46977 22.9117 3.63894C20.6205 2.28559 17.9899 1.43975 15.1047 1.43975C6.78864 1.52434 0 8.29107 0 16.6649C0 25.0387 6.78864 31.7209 15.1047 31.7209C23.4208 31.7209 30.2094 24.9541 30.2094 16.6649C30.2943 16.242 30.2094 15.8191 30.2943 15.3961C30.2094 15.3961 30.2943 15.3961 30.2943 15.3961ZM15.1047 23.4316C11.2861 23.4316 8.31608 20.3866 8.31608 16.5803C8.31608 12.774 11.371 9.729 15.1896 9.729C19.0082 9.729 22.0631 12.774 22.0631 16.5803C22.0631 20.3866 18.9233 23.4316 15.1047 23.4316Z"
      fill="#216CCF"
    />
  </svg>
);

const Spinner = () => (
  <div className="lds-ring">
    <div />
    <div />
    <div />
    <div />
  </div>
);
