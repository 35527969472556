import React, { useEffect, useState, useRef } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { mainAxiosInstance } from '@/services/networkAdapters/axiosInstance'
import { CustomInputText, CustomTextarea } from '@/components/CustomFormComponents'


// country code drop down
import countries2 from "@/utils/countries";
import { BsCheck2 } from "react-icons/bs";
import CustomSelect from '@/components/CustomSelect';
import styles2 from "@/components/LoginModal/LoginModal.module.scss";
import styles from "./SupportModal.module.scss";

const SupportModal = ({ showSupportModal, handleClose, countryCode, onChangeCountryCode, countryCodeDropdown, prependText = "", modalFor="" }) => {
  const user = useSelector((state) => state.user);
  console.log(user)
  const history = useHistory();
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    firstName: user?.name,
    lastName: "",
    phoneNumber: user?.mobile_number,
    email: user?.email_id,
    message: "",
    companyName: user?.organisation,
    offerId: "",
    type:"SUPPORT_MODAL"
  });


  // success modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const showModal = () => setIsModalOpen(true);

  const hideModal = () => setIsModalOpen(false);

  const onKeyPress = (event) => {
    if (event.key === 'Escape') {
      hideModal();
    }
  };

  // country code dropdown
  const countries = countries2.map((a) => ({
    name: a.name,
    dialCode: a.dialCode,
    isoCode: a.isoCode
  }));
  const options = countries.map((a) => ({
    value: `${a.name} (${a.dialCode})`,
    id: a.isoCode,
    dialCode: a.dialCode
  }));

  const allowedCountryCodes = [
    { label: "India", value: "+91", flag: "https://cdn.kcak11.com/CountryFlags/countries/in.svg", initials: "IN" },
    { label: "United Arab Emirates", value: "+971", flag: "https://cdn.kcak11.com/CountryFlags/countries/ae.svg", initials: "UAE" },
    { label: "Australia", value: "+61", flag: "https://cdn.kcak11.com/CountryFlags/countries/au.svg", initials: "AU" },
    { label: "Saudi Arabia", value: "+966", flag: "https://cdn.kcak11.com/CountryFlags/countries/sa.svg", initials: "SA" },
    { label: "Singapore", value: "+65", flag: "https://cdn.kcak11.com/CountryFlags/countries/sg.svg", initials: "SG" },
    { label: "United States", value: "+1", flag: "https://cdn.kcak11.com/CountryFlags/countries/us.svg", initials: "US" }
  ];


  const [selectedCountry, setSelectedCountry] = useState(allowedCountryCodes[0]);
  const [isListVisible, setIsListVisible] = useState(false)
  const handleCountryCodeChange = (value) => {
    const country = countries2.find((item) => item.dialCode === value);
    setSelectedCountry({
      label: country.name,
      value: country.dialCode,
      flag: country.flag,
      initials: country.isoCode
    });
    setIsListVisible((item) => !item)

    if (onChangeCountryCode) {
      onChangeCountryCode({ dialCode: country.dialCode, isoCode: country.isoCode });
    }
  }


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.firstName.trim()) {
      errors.firstName = 'First name is required';
    }

    // if (!values.lastName.trim()) {
    //   errors.lastName = 'Last name is required';
    // }

    if (formValues.phoneNumber.trim() === '') {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\+?\d{10,15}$/.test(formValues.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }

    // if (!values.email.trim()) {
    //   errors.email = 'Email is required';
    // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
    //   errors.email = 'Invalid email address';
    // }

    // if (!values.companyName && values.companyName.trim() === '') {
    //   errors.companyName = 'Company name is required';
    // }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm(formValues);
    setFormErrors(errors);

    let formValuesWithDialCode = null;
    if (selectedCountry && formValues.phoneNumber && Object.keys(errors).length === 0) {
      const phoneNumberWithDialCode = `${selectedCountry.value}${formValues.phoneNumber}`;
      formValuesWithDialCode = {
        ...formValues,
        phoneNumber: phoneNumberWithDialCode,
        message: `${prependText}${formValues.message}`
      };
    } else {
      formValuesWithDialCode = { ...formValues };
    }

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0 && formValuesWithDialCode) {
      console.log('Submitting:', formValuesWithDialCode);

      mainAxiosInstance.post(`/contact-sales/save-form`, formValuesWithDialCode)
        .then(() => {
          showModal();
          handleClose();
          // Clear form values
          setFormValues({
            ...formValues,
            message: '',
          });
        })
        .catch((err) => {
          // Handle error
          console.error(err);
        });
    }
  };

  return (
    <>
      <ReactModal
        className={styles.modalDialogue}
        overlayClassName={`${styles.modalOverlay} ${'postJob__completeBanner - overlay - 2'}`}
        // animation
        // centered
        isOpen={showSupportModal}
        onRequestClose={handleClose}
      >
        <div className={styles.close} onClick={handleClose}>
          <CloseIcon />
        </div>
        <div className={styles.header}>
          <div className={styles.title}><SupportIcon />{modalFor.toLowerCase() === "upgrade" ? "Contact Admin" : "Need Support"}</div>
          <div className={styles.subtitle}>{ modalFor.toLowerCase() === "upgrade" ? "Got a question or facing an issue? Connect with our dedicated support team to upgrade your plan and get the assistance you need." : "Got a question or facing an issue? Our dedicated support team is here to provide you with the assistance you need."}</div>
        </div>

        <form className={styles.container} onSubmit={handleSubmit}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>

            <CustomInputText
              placeholder="First Name"
              name="firstName"
              value={formValues.firstName}
              onChange={handleInputChange}
              errorMessage={formErrors.firstName}
            />
            {/* <CustomInputText
              placeholder="Last Name"
              name="lastName"
              value={formValues.lastName}
              onChange={handleInputChange}
              errorMessage={formErrors.lastName}
            /> */}
          </div>
          <div style={{ position: 'relative' }}>

            {countryCodeDropdown ? (
              <div className={`"phoneInput__countryCode-container"`}>
                <CustomSelect
                  options={options}
                  searchable
                  variant={5}
                  onChange={onChangeCountryCode}
                  selectedValue={countryCode}
                  type="country_code"

                />
              </div>
            ) : (
              <div className="phoneInput__countryCode-containersm">
                <div className={`${styles.selectCode} ${formErrors.phoneNumber ? styles.errorField : ''}`} onClick={() => setIsListVisible((item) => !item)} >
                  {/* <img src={selectedCountry.flag} alt="" className="flag" /> */}
                  {selectedCountry.initials} &nbsp;
                  {selectedCountry.value}
                </div>
                {isListVisible && <ul className={`selectUl ${styles.list}`}>
                  {allowedCountryCodes.map(code => (
                    <li
                      key={code.value}
                      value={code.value}
                      className={`selectOptions ${selectedCountry.value === code.value ? 'blueFont' : ''}`}
                      onClick={() => handleCountryCodeChange(code.value)}
                    >
                      {code.label}
                      {selectedCountry.value === code.value && <BsCheck2 className="blueCheck" />}
                    </li>
                  ))}
                </ul>}
              </div>
            )
            }
            <div className={styles.phoneInputContainer}>

              <CustomInputText
                placeholder="Enter your contant number"
                name="phoneNumber"
                value={formValues.phoneNumber}
                onChange={handleInputChange}
                errorMessage={formErrors.phoneNumber}
              />
            </div>
          </div>

          {/* <CustomInputText
            placeholder="Email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            errorMessage={formErrors.email}
          /> */}

          {/* <CustomInputText
            placeholder="Company Name"
            name="companyName"
            value={formValues.companyName}
            onChange={handleInputChange}
            errorMessage={formErrors.companyName}
          /> */}


          <CustomTextarea
            placeholder="Message"
            name="message"
            value={formValues.message}
            onChange={handleInputChange}
            errorMessage={formErrors.message}
            style={{ minHeight: '56px', height: '222px', maxHeight: '300px' }}
          />


          <button type="submit" className={styles.btn} onClick={handleSubmit}>
            Send your message
          </button>
        </form>

      </ReactModal>

      <ReactModal isOpen={isModalOpen}
        className={styles.modalDialogue}
        overlayClassName={`${styles.modalOverlay} ${'postJob__completeBanner - overlay - 2'}`}
        onRequestClose={hideModal}
      >
        <div className={styles.modalcontainer} ref={modalRef} tabIndex="-1" onKeyDown={onKeyPress}>
          <div className={styles.modallogo}>
            <Ticklogo />
          </div>
          <div className={styles.modaltitle}>We got your message</div>
          <div className={styles.modaltext}>
            Our team will get in touch with you shortly
          </div>
          <button className={styles.btn} style={{ width: '66%' }}
            onClick={() => {
              hideModal()
              history.push('/')
            }}>Start Hiring
          </button>
        </div>
      </ReactModal>
    </>

  )
}

export default SupportModal

const CloseIcon = () => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.75 7.25L7.25 21.75" stroke="#9DA3A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.25 7.25L21.75 21.75" stroke="#9DA3A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

const SupportIcon = () => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6705_7858)">
      <path d="M6.5 14.5V9.24C6.51562 8.20606 6.73511 7.18537 7.14588 6.2364C7.55665 5.28744 8.15063 4.42885 8.8938 3.70984C9.63696 2.99083 10.5147 2.42552 11.4767 2.04632C12.4387 1.66711 13.4661 1.48146 14.5 1.5C15.5339 1.48146 16.5613 1.66711 17.5233 2.04632C18.4853 2.42552 19.363 2.99083 20.1062 3.70984C20.8494 4.42885 21.4434 5.28744 21.8541 6.2364C22.2649 7.18537 22.4844 8.20606 22.5 9.24V14.5M18.5 25C19.5609 25 20.5783 24.5786 21.3284 23.8284C22.0786 23.0783 22.5 22.0609 22.5 21V16.5M18.5 25C18.5 25.663 18.2366 26.2989 17.7678 26.7678C17.2989 27.2366 16.663 27.5 16 27.5H13C12.337 27.5 11.7011 27.2366 11.2322 26.7678C10.7634 26.2989 10.5 25.663 10.5 25C10.5 24.337 10.7634 23.7011 11.2322 23.2322C11.7011 22.7634 12.337 22.5 13 22.5H16C16.663 22.5 17.2989 22.7634 17.7678 23.2322C18.2366 23.7011 18.5 24.337 18.5 25ZM3.5 11.5H5.5C5.76522 11.5 6.01957 11.6054 6.20711 11.7929C6.39464 11.9804 6.5 12.2348 6.5 12.5V18.5C6.5 18.7652 6.39464 19.0196 6.20711 19.2071C6.01957 19.3946 5.76522 19.5 5.5 19.5H3.5C2.96957 19.5 2.46086 19.2893 2.08579 18.9142C1.71071 18.5391 1.5 18.0304 1.5 17.5V13.5C1.5 12.9696 1.71071 12.4609 2.08579 12.0858C2.46086 11.7107 2.96957 11.5 3.5 11.5ZM25.5 19.5H23.5C23.2348 19.5 22.9804 19.3946 22.7929 19.2071C22.6054 19.0196 22.5 18.7652 22.5 18.5V12.5C22.5 12.2348 22.6054 11.9804 22.7929 11.7929C22.9804 11.6054 23.2348 11.5 23.5 11.5H25.5C26.0304 11.5 26.5391 11.7107 26.9142 12.0858C27.2893 12.4609 27.5 12.9696 27.5 13.5V17.5C27.5 18.0304 27.2893 18.5391 26.9142 18.9142C26.5391 19.2893 26.0304 19.5 25.5 19.5Z" stroke="#3B4045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6705_7858">
        <rect width="28" height="28" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>

)

const Ticklogo = () => (
  <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M78.875 48.6257C76.125 62.3757 65.7575 75.3227 51.21 78.2157C44.115 79.6287 36.755 78.7672 30.178 75.7539C23.6011 72.7406 18.1425 67.7291 14.5794 61.433C11.0164 55.1369 9.53057 47.8771 10.3335 40.6874C11.1365 33.4978 14.1873 26.7447 19.0515 21.3897C29.0285 10.4007 45.875 7.37574 59.625 12.8757" stroke="#297BCA" strokeWidth="5.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M32.125 43.125L45.875 56.875L78.875 21.125" stroke="#297BCA" strokeWidth="5.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);