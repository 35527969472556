// const prod = false;
const prod = true;
//

export default {
  nodeEnv: prod ? "PRODUCTION" : "DEVELOPMENT",
  dropConsole: prod,
  razorpay_key: prod ? "rzp_live_xwpLwNLpvgqByT" : "rzp_test_MDvIBf7EU4kxRg",
  apiUrl: prod
    ? "https://api.goodspace.ai"
    : "https://staging-api.goodspace.ai",
  // : "http://localhost:5001/api/d2",

  baseUrl: prod
    ? "https://api.goodspace.ai/api/d2"
    : "https://staging-api.goodspace.ai/api/d2",
  // : "http://localhost:5001/api/d2",

  ccaAccessCode: "AVIL37KC05BW93LIWB",
  socialBaseUrl: prod
    ? "https://social-api.ourgoodspace.com/api/v1"
    : "https://staging-social-api.ourgoodspace.com/api/v1",
  // : "http://localhost:5002/api/v1",
  chatBaseUrl: prod
    ? "https://chat.ourgoodspace.com/"
    : "https://chat-dev.ourgoodspace.com/",
  // : "https://chat-dev.ourgoodspace.com/",
  interviewBaseUrl: prod
    ? "https://agents.goodspace.ai/"
    : "https://airecruiter-dev.goodspace.ai",
  // : "http://localhost:4002",
  socketBaseUrl: prod
    ? "wss://chat.trustze.com"
    : "wss://chat-dev.ourgoodspace.com",
  interviewSocketBaseUrl: prod
    ? "wss://agents.goodspace.ai"
    : "wss://airecruiter-dev.goodspace.ai",
  // : "ws://localhost:4002",
  gsPitchBaseUrl: prod
    ? "https://api-gs-pitch.goodspace.ai"
    : "https://api-dev-pitch.goodspace.ai",
  // : "http://localhost:4005",
  gsPitchSocketBaseUrl: prod
    ? "wss://api-gs-pitch.goodspace.ai"
    : "wss://api-dev-pitch.goodspace.ai",
  // : "wss://localhost:4005",
  mapboxAccessToken:
    "pk.eyJ1IjoiZ29vZHNwYWNlbWFwcyIsImEiOiJjbHM4dGV1N28wMGY4MnZseGg1aWpxZHVjIn0.VOukyKp2CXk6Pq9E0IGSxA",
  branchKey: !prod
    ? "key_live_on7uLbT3K33Nf3REk8cTcanmEuofKwZs"
    : "key_test_nl5qKmH7VYYNe2Qxo7fUllngqvnoRC2U",
  // recaptcha key
  recaptchaKey: "6LfXj_onAAAAAEccbZYfTC_CW-FpuXoLnFVPgIpt",
  intercomAppId: "vrtnbnli",
  intercomSecretKey: "ylU9fODx0OvipfnRkJGf5QK3CdC1ZcVgWxO2tWJS"
};
