import logoicon from "@/assets/logo/logo.svg";
import config from "@/config/appConfig";
import {
  mainAxiosInstance as axiosInstance,
  socialAxiosInstance
} from "@/services/networkAdapters/axiosInstance";
import { logFirebaseEvent } from "@/services/firebase";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const displayRazorpay = async ({
  name,
  email,
  phone_number,
  amount,
  paymentSuccess
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await axiosInstance.get("/profile/orderId");
  if (data.data.data.orderDone) {
    paymentSuccess();
    return;
  }
  console.log(data.data.data);

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency: "INR",
    amount,
    order_id: data.data.data.orderId,
    name: "Premium Upgrade ",
    description: "Plus Plan enhances your Job Search",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      axiosInstance
        .post("/profile/paymentinfo", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then(async (res) => {
          paymentSuccess();
          window.fbq("track", "Purchase", { value: amount, currency: "INR" });
          logFirebaseEvent("Purchase", {
            value: amount,
            currency: "INR"
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          // alert("Payment Failed");.
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};

const displayRazorpayHirer = async ({
  name,
  email,
  phone_number,
  planName,
  paymentSuccess,
  amount,
  currency,
  paymentFail,
  planDetails
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await axiosInstance.post("/hirer/init_purchase_add_on", {
    planName,
    currency
  });

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency: currency || "INR",
    amount,
    order_id: data.data.data.orderId,
    name: planDetails ? planDetails.display_name : "Premium Plan",
    description: "",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      axiosInstance
        .post("/hirer/complete_purchase_add_on", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then(async (res) => {
          paymentSuccess();
          window.fbq("track", "Purchase", {
            value: amount,
            planName,
            currency
          });
          logFirebaseEvent("Purchase", {
            value: amount,
            planName,
            currency
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          paymentFail();
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
const displayRazorpayMember = async ({
  name,
  email,
  phone_number,
  planName,
  paymentSuccess,
  amount,
  currency="INR",
  paymentFail,
  planDetails,
  planLocation ="DEFAULT"
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await axiosInstance.post("/member/initiate_purchase", {
    planName,
    planLocation,
    currency
  });

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency: currency || "INR",
    amount,
    order_id: data.data.data.orderId,
    name: planDetails ? planDetails.display_name : "Premium Plan",
    description: "",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      axiosInstance
        .post("/member/verify_purchase", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then(async (res) => {
          paymentSuccess();
          window.fbq("track", "Purchase", {
            value: amount,
            planName,
            currency
          });
          logFirebaseEvent("Purchase", {
            value: amount,
            planName,
            currency
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          paymentFail();
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
const displayRazorpayRecruiterGCoins = async ({
  name,
  email,
  phone_number,
  paymentSuccess,
  amount,
  currency="INR",
  paymentFail
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await axiosInstance.post("/gcoins/recruiter/create_order", {
    amount,
    currency
  });

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency: currency || "INR",
    amount,
    order_id: data.data.data.orderId,
    name: "GoodSpace",
    description: "",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      axiosInstance
        .post("/gcoins/recruiter/complete_order", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then(async (res) => {
          paymentSuccess(res.data.gcoinsValue);
          window.fbq("track", "Purchase", {
            value: amount,
            planName: `GCOINS ${amount}`,
            currency
          });
          logFirebaseEvent("Purchase", {
            value: amount,

            planName: `GCOINS ${amount}`,
            currency
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          paymentFail();
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
const displayRazorpayHirer2 = async ({
  name,
  email,
  phone_number,
  planName,
  paymentSuccess,
  amount,
  currency,
  paymentFail,
  planDetails
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await axiosInstance.post("/hirer/initiate_purchase", {
    planName,
    currency,
    paymentGateway: "CCA"
  });
  console.log(data);

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency: currency || "INR",
    amount,
    order_id: data.data.data.orderId,
    name: planDetails ? planDetails.display_name : "Premium Plan",
    description: "",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      axiosInstance
        .post("/hirer/verify_purchase", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then(async (res) => {
          paymentSuccess();
          window.fbq("track", "Purchase", {
            value: amount,
            planName,
            currency
          });
          logFirebaseEvent("Purchase", {
            value: amount,
            planName,
            currency
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          paymentFail();
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};

const displayRazorpayPitch = async ({
  name,
  email,
  phone_number,
  planName,
  paymentSuccess,
  amount,
  currency = "INR",
  paymentFail,
  planDetails
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await axiosInstance.post("/pitch/init_purchase", {
    planName,
    currency
  });

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency: currency || "INR",
    amount,
    order_id: data.data.data.orderId,
    name: planDetails ? planDetails.display_name : "Premium Plan",
    description: "",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      axiosInstance
        .post("/pitch/complete_purchase", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then(async (res) => {
          paymentSuccess();
          window.fbq("track", "Purchase", {
            value: amount,
            planName,
            currency
          });
          logFirebaseEvent("Purchase", {
            value: amount,
            planName,
            currency
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          paymentFail();
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};

const displayRazorpayIntro = async ({
  name,
  email,
  phone_number,
  planName,
  paymentSuccess,
  amount,
  currency = "INR",
  paymentFail,
  planDetails
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await socialAxiosInstance.post("/premium/init_intro_add_on", {
    planName,
    currency
  });

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency: currency || "INR",
    amount,
    order_id: data.data.data.orderId,
    name: planDetails ? planDetails.display_name : "Premium Plan",
    description: "",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      socialAxiosInstance
        .post("/premium/complete_intro_add_on", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then(async (res) => {
          window.fbq("track", "Purchase", {
            value: amount,
            planName,
            currency
          });
          logFirebaseEvent("Purchase", {
            value: amount,
            planName,
            currency
          });
          paymentSuccess();
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          paymentFail();
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
const displayRazorpaySocial = async ({
  name,
  email,
  phone_number,
  planName,
  paymentSuccess,
  amount,
  paymentFail,
  planDisplayName,
  currency = "INR"
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await socialAxiosInstance.post("/premium/init_purchase_add_on", {
    planName,
    currency
  });

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency,
    amount,
    order_id: data.data.data.orderId,
    name: planDisplayName,
    // description: "Plus Plan enhances your Job Search",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      socialAxiosInstance
        .post("/premium/complete_purchase_add_on", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then(async (res) => {
          window.fbq("track", "Purchase", {
            value: amount,
            planName,
            currency
          });
          logFirebaseEvent("Purchase", {
            value: amount,
            planName,
            currency
          });
          paymentSuccess();
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          paymentFail();
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};

const displayRazorpayGcoins = async ({
  name,
  email,
  phone_number,
  gCoinsCode,
  paymentSuccess,
  amount,
  paymentFail
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await axiosInstance.post("/gcoins/create_order", {
    gCoinsCode
  });

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency: "INR",
    amount,
    order_id: data.data.data.orderId,
    name: "Buy Gcoins",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      axiosInstance
        .post("/gcoins/complete_order", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then(async (res) => {
          paymentSuccess(res.data.gcoinsValue);
          window.fbq("track", "Purchase", {
            value: amount,
            planName: gCoinsCode,
            currency: "INR"
          });
          logFirebaseEvent("Purchase", {
            value: amount,
            planName: gCoinsCode,
            currency: "INR"
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          paymentFail();
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
const displayRazorpayBoostJobs = async ({
  name,
  email,
  phone_number,
  planName,
  currency,
  paymentSuccess,
  amount,
  paymentFail,
  offerId
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await axiosInstance.post("/hirer/init_boost_job_purchase", {
    planName,
    offerId,
    currency
  });

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency: currency || "INR",
    amount,
    order_id: data.data.data.orderId,
    name: "Boost Job",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      axiosInstance
        .post("/hirer/complete_boost_job_purchase", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then((res) => {
          paymentSuccess();
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          paymentFail();
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};

const displayRazorpayChatbot = async ({
  name,
  email,
  phone_number,
  planName,
  paymentSuccess,
  amount,
  currency,
  paymentFail,
  planDetails
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    // alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const data = await axiosInstance.post("/chatbot/initiate_purchase", {
    planName,
    currency
  });

  const options = {
    key: config.razorpay_key,
    // callback_url: `http://cb6d88877e13.ngrok.io/api/d2/config/razorpaycallback?id=${data.data.data.paymentsId}`,
    redirect: false,
    currency: currency || "INR",
    amount,
    order_id: data.data.data.orderId,
    name: planDetails ? planDetails.display_name : "Premium Plan",
    description: "",
    image: logoicon,
    handler: (response) => {
      console.log(response.razorpay_signature);
      axiosInstance
        .post("/chatbot/verify_purchase", {
          razorpay_signature: response.razorpay_signature,
          payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          paymentsId: data.data.data.paymentsId
        })
        .then(async (res) => {
          paymentSuccess();
          window.fbq("track", "Purchase", {
            value: amount,
            planName,
            currency
          });
          logFirebaseEvent("Purchase", {
            value: amount,
            planName,
            currency
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
          paymentFail();
        });
    },
    prefill: {
      name,
      email,
      contact:phone_number
    }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
export default displayRazorpay;
export {
  displayRazorpayHirer,
  displayRazorpaySocial,
  displayRazorpayGcoins,
  displayRazorpayBoostJobs,
  displayRazorpayPitch,
  displayRazorpayIntro,
  displayRazorpayMember,
  displayRazorpayHirer2,
  displayRazorpayChatbot,
  displayRazorpayRecruiterGCoins
};
