import { LOAD_STATIC_CONFIG } from "../CONSTANTS";

const initialState = {
  loaded: false,
  currentAvailability: [],
  highestQualification: [],
  hourlyMaster: [],
  monthlyMaster: [],
  parameters: [],
  relationship: [],
  skill_level: [],
  typeOfWork: [],
  interviewTypes: [],
  baseInterviewCost: 0
};
const staticConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_STATIC_CONFIG:
      return { loaded: true, ...action.payload };
    default:
      return state;
  }
};
export default staticConfigReducer;
