import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { CustomFormSelectWrapper } from "@/components/CustomFormComponents";
import { useSelector, useDispatch } from "react-redux";
import { updateFilterMainJob } from "@/redux/actions/filterActions";
import { fetchSpecificConfigApi } from "@/services/utilitiesService";
import { showToastMessage } from "@/components/ToastComponent";
import styles from './SearchBar.module.scss';


const SearchBar = ({ text = "My Workspace" }) => {
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.jobPageData.filterData);
  const staticConfig = useSelector((state) => state.staticConfig);
  const history = useHistory();
  const [inputData, setInputData] = useState({});
  const jobTitleSelected = useRef([]);
  const skillSelected = useRef([]);

  // const [jobTitleSelected, setJobTitleSelected] = useState([]);
  // const [skillSelected, setSkillSelected] = useState([]);
  const [list, setList] = useState();
  const [isActive, setIsActive] = useState(false);
  const [isActiveDelayed, setIsActiveDelayed] = useState(false);
  const [designationData, setDesignationData] = useState();
  const [expRequiredData, setExpRequiredData] = useState('');
  const [location, setLocation] = useState([]);
  const [errors, setErrors] = useState({
    designationData: null,
    expRequiredData: null,
    location: null,
  });

  const handleExpand = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (!isActive) {
      setIsActiveDelayed(isActive);
    } else {
      const timer = setTimeout(() => {
        setIsActiveDelayed(isActive);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [isActive]);

  const getValues = (type) => {
    const url = `config/searchConfig?config=${type}&search=${"%"}`
    return fetchSpecificConfigApi(url).then((res) => res.data.data.map((a) => ({ value: a.id, label: a.value,type })));
  };

  useEffect(() => {
    Promise.all([getValues("designation"), getValues("skill")]).then(([designations, skills]) => {
      setList([...designations, ...skills]);
      console.log([...designations, ...skills].length, "length");
    });
  }, []);

  const applyFilter = () => {
    const dispatchFilters = {
      addedToCollection: false,
    };

    if (expRequiredData !== '') {
      dispatchFilters.expCategory = expRequiredData.category;
    }

    if (designationData?.label!=="" && designationData?.label!==null) {
      dispatchFilters.designation = designationData;
    }

    if (inputData?.inputs?.city) {
      const pathName = history.location.pathname;
      const cityAlready = history.location.pathname.includes("jobs-in-")
        ? history.location.pathname.split("jobs-in-")[1]
        : "";
      if (cityAlready)
        history.push(
          pathName.replace(cityAlready, inputData?.inputs?.city.replaceAll(", ", "-"))
        );
      else history.push(`/jobs-in-${inputData?.inputs?.city.replaceAll(", ", "-")}`);
    } else {
      history.push("/jobs");
    }

    const updatedFilterData = {
      ...filterData,
      ...dispatchFilters,
      inputs: {
        ...filterData.inputs,
        ...inputData.inputs,
      },
      jobTitleSelected:inputData.jobTitleSelected,
      skillSelected:inputData.skillSelected,
    };
    // if(!inputData?.inputs?.city){
    //   showToastMessage("error", "Please select at least one location.");
    // }

    dispatch(updateFilterMainJob(updatedFilterData));
  };

  const getCity = (e) => {
    setInputData((prev) => ({
      ...prev,
      inputs: { ...prev.inputs, city: e ? e.label : null }
    }));
  };

  const handleExperienceChange = (value) => {
    let inputs = { ...filterData.inputs };
    let expCategory = value;
    if (filterData.expCategory === value) {
      delete inputs.lowerworkex;
      delete inputs.upperworkex;
      expCategory = null;
    } else {
      switch (value) {
        case 1:
          inputs = { ...inputs, lowerworkex: 0, upperworkex: 1 };
          break;
        case 2:
          inputs = { ...inputs, lowerworkex: 1, upperworkex: 3 };
          break;
        case 3:
          inputs = { ...inputs, lowerworkex: 3, upperworkex: 5 };
          break;
        case 4:
          inputs = { ...inputs, lowerworkex: 5, upperworkex: 10 };
          break;
        case 5:
          inputs = { ...inputs, lowerworkex: 10, upperworkex: 15 };
          break;
        case 6:
          inputs = { ...inputs, lowerworkex: 15, upperworkex: 30 };
          break;
        default:
          break;
      }
    }
    delete inputs.addedToCollection;
    setInputData((prev) => ({ ...prev, inputs, expCategory }));
  };

  const handleInputChange = (e, value) => {
    let inputs = { ...filterData.inputs };
 
   switch (value) {
     case "designation":
       if (Number.isInteger(e.value)) {
         for (let i = 0; i < filterData.jobTitleSelected.length; i += 1) {
           if (filterData.jobTitleSelected[i].value === e.value) {
             showToastMessage("error", "Job Title already selected!");
             return;
           }
         }
         const temp = [
           ...filterData.jobTitleSelected,
           {
             id: e.value,
             value: e.label,
           },
         ];
         jobTitleSelected.current=temp;
         inputs = {
           ...inputs,
           designation: temp.map((id) => id.id),
         };
       }
       break;
     case "skill":
       if (Number.isInteger(e.value)) {
         for (let i = 0; i < filterData.skillSelected.length; i += 1) {
           if (filterData.skillSelected[i].value === e.value) {
             showToastMessage("error", "Skill already selected!");
             return;
           }
         }
         const temp = [
           ...filterData.skillSelected,
           {
             id: e.value,
             value: e.label,
           },
         ];
         skillSelected.current=temp;
         inputs = {
           ...inputs,
           skills: temp.map((id) => id.id),
         };
       }
       break;
     default:
       break;
   }
 
   setInputData((prev) => ({
     ...prev,
     inputs: { ...prev.inputs, ...inputs },
     jobTitleSelected: [...jobTitleSelected.current],
     skillSelected: [...skillSelected.current],
   }));
  };

  return (
    <>
      <div className={` ${isActive ? styles.overlay : ''}`} onClick={handleClose} />
      <div className={`${styles.navContainer}  ${isActive ? styles.activenav : ''}`}>
        <div className={styles.logoplaceholder} />
        <div className={styles.gslogo} onClick={() => history.push("/")}>
          <div><LogoIcon /></div>
          <div>{text}</div>
        </div>
        <div className={`${styles.box} ${isActive ? styles.activebox : ''}`} onClick={handleExpand} >
          {isActiveDelayed ?
            <div className={styles.form}>
              <CustomFormSelectWrapper
                // type="designation"
                theme={errors.designationData ? "errorOnboardingV2" : "OnboardingV2"}
                // async
                onChange={(e) => {
                  handleInputChange(e, e.type);
                  const concatenatedLabels = `${designationData?`${designationData?.label},`:''}${e.label}`

                setDesignationData({ label: concatenatedLabels })
                }}
                options={list}
                value={designationData}
                placeholder="Enter Job title/Skills"
                errorMessage={errors.designationData?.placeHolderMessage}
              />
              <CustomFormSelectWrapper
                required
                theme={errors.expRequiredData ? "errorOnboardingV2" : "OnboardingV2"}
                options={
                  staticConfig.loaded &&
                  staticConfig.workExperienceRange.map((item, index) =>
                    item.upperworkex === 60
                      ? {
                        label: "More than 30 Years",
                        value: `${item.upperworkex}${item.lowerworkex}`,
                        category: 8,
                        ...item
                      }
                      : {
                        label: `${item.lowerworkex}-${item.upperworkex} Years`,
                        value: `${item.upperworkex}${item.lowerworkex}`,
                        category: index + 1,
                        ...item
                      }
                  )
                }
                onChange={(e) => {
                  setExpRequiredData(e);
                  handleExperienceChange(e.category);
                  setErrors((e) => ({ ...e, expRequiredData: null }));
                }}
                value={expRequiredData}
                placeholder="Select experience"
                errorMessage={errors.expRequiredData?.placeHolderMessage}
              />
              <CustomFormSelectWrapper
                required
                type="location"
                theme={errors.location ? "errorOnboardingV2" : "OnboardingV2"}
                async
                onChange={(e) => {
                  setLocation(prevLocations => [...prevLocations, e]);
                  setErrors((e) => ({ ...e, location: null }));
                  getCity(e);
                }}
                value={location}
                placeholder="Enter location"
                errorMessage={errors.location?.placeHolderMessage}
              />
            </div>
            :
            <div className={`${styles.placeholder}`}> Search job title</div>
          }
          <div className={`${styles.btn}`} onClick={applyFilter}><SearchIcon /></div>
        </div>
        <div className={styles.space} />
      </div>
    </>
  );
};

export default SearchBar;



const NavbarLogoWithText = ({ text, history }) => (
  <>
    {/* <div className="dashboardNavbar__logo-withText-container" onClick={() => history.push("/")} style={{ cursor: 'pointer' }}>
      <div className="dashboardNavbar__logo-withText-icon"      >
        <LogoIcon />
      </div>
      <div className="dashboardNavbar__logo-withText-text" >{text}</div>
    </div> */}
    <div className={styles.gslogo} onClick={() => history.push("/")}>
      <div ><LogoIcon /></div>
      <div >{text}</div>
    </div>
    <div className="dashboardNavbar__logo-withText-container-sm"
      onClick={() => history.push("/")}>
      <LogoWithText />
    </div>
  </>
);

const LogoIcon = () => (
  <svg width="26" height="41" viewBox="0 0 26 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.352679 31.435C0.281941 31.8617 0.211204 32.5018 0.564892 33.4974C1.69669 36.5554 5.30431 38.3333 6.08242 38.6889C9.0534 40.1112 11.7414 40.1823 12.944 40.1823C13.5099 40.1823 15.7735 40.1823 18.603 39.2578C19.664 38.9022 21.2203 38.4044 22.8472 37.1954C24.4035 36.0576 25.0401 34.9908 25.2523 34.7064C25.2523 34.7064 25.2523 34.7064 25.2523 34.6352C25.4645 34.2797 26.1719 33.1418 25.9597 31.7195C25.9597 31.5061 25.6767 30.1549 24.5449 29.3015C24.5449 29.3015 22.635 28.0214 20.5129 29.1593C20.4422 29.1593 20.3714 29.2304 20.3714 29.2304C20.2299 29.3727 20.0885 29.4438 20.0885 29.4438C19.8763 29.6571 19.0981 30.3683 19.0274 30.4394C19.0274 30.4394 18.5322 30.8661 17.259 31.7195C17.1175 31.7906 16.976 31.9328 16.976 31.9328L16.9053 32.0039C16.5516 32.2173 15.8442 32.5729 14.9954 32.7862C14.3587 32.9285 13.0147 33.3552 11.317 32.9285C9.90226 32.644 8.91193 31.9328 8.4875 31.6484C7.85086 31.2217 7.78013 31.0083 6.36538 29.8705C5.30431 29.0171 5.0921 28.8748 4.73841 28.8037C3.46513 28.377 2.26259 29.0171 2.12112 29.1593C1.97964 29.0882 0.635629 29.8705 0.352679 31.435Z" fill="#297BCA" />
    <path d="M25.2533 12.9447C25.2533 9.53109 25.3241 6.0464 25.3241 2.63283C25.2533 2.34836 25.1119 1.99278 24.7582 1.56609C24.546 1.21051 23.9801 0.499344 22.9897 0.143764C22.7775 0.0726476 21.9994 -0.140701 21.1505 0.143764C20.0187 0.499344 19.5236 1.49497 19.3821 1.70832C19.1699 2.0639 19.0992 2.41948 19.0992 2.63283C19.0992 2.77506 19.0992 2.91729 19.0992 3.05952C17.1892 1.92167 14.9964 1.21051 12.5913 1.21051C5.65901 1.28162 0 6.97091 0 14.0114C0 21.0519 5.65901 26.6701 12.5913 26.6701C19.5236 26.6701 25.1826 20.9808 25.1826 14.0114C25.2533 13.6558 25.1826 13.3002 25.2533 12.9447C25.2533 12.9447 25.1826 12.9447 25.2533 12.9447ZM12.5913 19.7007C9.4081 19.7007 6.93229 17.1405 6.93229 13.9403C6.93229 10.7401 9.47884 8.17988 12.662 8.17988C15.8452 8.17988 18.3918 10.7401 18.3918 13.9403C18.3918 17.1405 15.7745 19.7007 12.5913 19.7007Z" fill="#297BCA" />
  </svg>

)

const LogoWithText = () => (
  <svg width="109" height="24" viewBox="0 0 109 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.211881 18.7756C0.169505 19.0304 0.127129 19.4127 0.33901 20.0073C1.01703 21.8338 3.17822 22.8956 3.64436 23.108C5.42417 23.9575 7.03446 24 7.75486 24C8.09387 24 9.44991 24 11.145 23.4478C11.7806 23.2355 12.7129 22.9381 13.6875 22.216C14.6198 21.5364 15.0012 20.8993 15.1283 20.7294C15.1283 20.7294 15.1283 20.7294 15.1283 20.6869C15.2555 20.4746 15.6792 19.795 15.5521 18.9455C15.5521 18.818 15.3826 18.011 14.7046 17.5013C14.7046 17.5013 13.5604 16.7367 12.2891 17.4163C12.2467 17.4163 12.2044 17.4588 12.2044 17.4588C12.1196 17.5438 12.0349 17.5862 12.0349 17.5862C11.9077 17.7137 11.4416 18.1384 11.3992 18.1809C11.3992 18.1809 11.1026 18.4358 10.3398 18.9455C10.2551 18.9879 10.1703 19.0729 10.1703 19.0729L10.1279 19.1154C9.91605 19.2428 9.49229 19.4552 8.98377 19.5826C8.60239 19.6675 7.79724 19.9224 6.78021 19.6675C5.93268 19.4976 5.33941 19.0729 5.08516 18.903C4.70377 18.6481 4.66139 18.5207 3.81387 17.8411C3.17822 17.3314 3.05109 17.2464 2.83921 17.204C2.07644 16.9491 1.35604 17.3314 1.27129 17.4163C1.18654 17.3739 0.381387 17.8411 0.211881 18.7756Z" fill="#297BCA" />
    <path d="M15.1283 7.7314C15.1283 5.69259 15.1707 3.61131 15.1707 1.5725C15.1283 1.4026 15.0436 1.19022 14.8317 0.935369C14.7046 0.722993 14.3656 0.298241 13.7723 0.0858651C13.6452 0.0433899 13.179 -0.0840357 12.6705 0.0858651C11.9925 0.298241 11.6959 0.892894 11.6111 1.02032C11.484 1.2327 11.4416 1.44507 11.4416 1.5725C11.4416 1.65745 11.4416 1.7424 11.4416 1.82735C10.2974 1.14775 8.98377 0.722993 7.54298 0.722993C3.3901 0.765468 0 4.16348 0 8.36853C0 12.5736 3.3901 15.9291 7.54298 15.9291C11.6959 15.9291 15.086 12.5311 15.086 8.36853C15.1283 8.15615 15.086 7.94378 15.1283 7.7314ZM7.54298 11.7665C5.63605 11.7665 4.15288 10.2374 4.15288 8.32605C4.15288 6.41467 5.67842 4.88556 7.58536 4.88556C9.49229 4.88556 11.0178 6.41467 11.0178 8.32605C11.0178 10.2374 9.44991 11.7665 7.54298 11.7665Z" fill="#297BCA" />
    <path d="M26.6065 6.77787C27.2336 6.77787 27.7848 6.9046 28.2602 7.15804C28.7356 7.40135 29.1098 7.7207 29.3829 8.11608V6.91473H31.5221V15.4002C31.5221 16.1808 31.3653 16.8752 31.0518 17.4835C30.7382 18.1019 30.2679 18.5886 29.6408 18.9434C29.0137 19.3084 28.2551 19.4908 27.3651 19.4908C26.1716 19.4908 25.1905 19.212 24.4218 18.6545C23.6632 18.0969 23.2334 17.3365 23.1322 16.3734H25.2411C25.3523 16.7587 25.59 17.0628 25.9541 17.2858C26.3284 17.519 26.7785 17.6356 27.3044 17.6356C27.9214 17.6356 28.422 17.448 28.8064 17.0729C29.1907 16.708 29.3829 16.1504 29.3829 15.4002V14.0924C29.1098 14.4878 28.7305 14.8173 28.245 15.0808C27.7697 15.3444 27.2235 15.4762 26.6065 15.4762C25.8985 15.4762 25.2512 15.2937 24.6646 14.9288C24.0779 14.5638 23.6127 14.0518 23.2688 13.3929C22.935 12.7238 22.7681 11.9584 22.7681 11.0966C22.7681 10.245 22.935 9.48977 23.2688 8.8308C23.6127 8.17184 24.0729 7.66494 24.6494 7.31011C25.236 6.95529 25.8884 6.77787 26.6065 6.77787ZM29.3829 11.127C29.3829 10.61 29.2817 10.169 29.0795 9.80404C28.8772 9.42894 28.6041 9.14508 28.2602 8.95246C27.9163 8.7497 27.5471 8.64832 27.1527 8.64832C26.7582 8.64832 26.3941 8.74463 26.0603 8.93725C25.7266 9.12987 25.4535 9.41373 25.2411 9.78884C25.0388 10.1538 24.9376 10.5897 24.9376 11.0966C24.9376 11.6035 25.0388 12.0496 25.2411 12.4348C25.4535 12.8099 25.7266 13.0989 26.0603 13.3016C26.4042 13.5044 26.7683 13.6058 27.1527 13.6058C27.5471 13.6058 27.9163 13.5095 28.2602 13.3168C28.6041 13.1141 28.8772 12.8302 29.0795 12.4653C29.2817 12.0901 29.3829 11.6441 29.3829 11.127Z" fill="#297BCA" />
    <path d="M37.3287 15.4762C36.5196 15.4762 35.7913 15.2988 35.144 14.944C34.4967 14.579 33.9859 14.067 33.6117 13.4081C33.2476 12.7491 33.0655 11.9888 33.0655 11.127C33.0655 10.2653 33.2526 9.50498 33.6269 8.84601C34.0112 8.18705 34.5321 7.68015 35.1895 7.32532C35.847 6.96035 36.5803 6.77787 37.3894 6.77787C38.1985 6.77787 38.9318 6.96035 39.5893 7.32532C40.2467 7.68015 40.7625 8.18705 41.1368 8.84601C41.5211 9.50498 41.7133 10.2653 41.7133 11.127C41.7133 11.9888 41.516 12.7491 41.1216 13.4081C40.7372 14.067 40.2113 14.579 39.5438 14.944C38.8863 15.2988 38.148 15.4762 37.3287 15.4762ZM37.3287 13.621C37.7131 13.621 38.0721 13.5297 38.4059 13.3473C38.7498 13.1546 39.0229 12.8708 39.2251 12.4957C39.4274 12.1206 39.5286 11.6644 39.5286 11.127C39.5286 10.3261 39.3162 9.7128 38.8914 9.28701C38.4767 8.85108 37.9659 8.63311 37.3591 8.63311C36.7522 8.63311 36.2414 8.85108 35.8267 9.28701C35.4222 9.7128 35.2199 10.3261 35.2199 11.127C35.2199 11.9279 35.4171 12.5464 35.8116 12.9823C36.2161 13.4081 36.7219 13.621 37.3287 13.621Z" fill="#297BCA" />
    <path d="M47.0035 15.4762C46.1944 15.4762 45.4661 15.2988 44.8188 14.944C44.1715 14.579 43.6607 14.067 43.2865 13.4081C42.9224 12.7491 42.7403 11.9888 42.7403 11.127C42.7403 10.2653 42.9274 9.50498 43.3017 8.84601C43.686 8.18705 44.2069 7.68015 44.8643 7.32532C45.5218 6.96035 46.255 6.77787 47.0642 6.77787C47.8733 6.77787 48.6066 6.96035 49.264 7.32532C49.9215 7.68015 50.4373 8.18705 50.8115 8.84601C51.1959 9.50498 51.3881 10.2653 51.3881 11.127C51.3881 11.9888 51.1908 12.7491 50.7964 13.4081C50.412 14.067 49.8861 14.579 49.2185 14.944C48.5611 15.2988 47.8228 15.4762 47.0035 15.4762ZM47.0035 13.621C47.3878 13.621 47.7469 13.5297 48.0807 13.3473C48.4246 13.1546 48.6977 12.8708 48.8999 12.4957C49.1022 12.1206 49.2034 11.6644 49.2034 11.127C49.2034 10.3261 48.991 9.7128 48.5662 9.28701C48.1515 8.85108 47.6407 8.63311 47.0338 8.63311C46.427 8.63311 45.9162 8.85108 45.5015 9.28701C45.097 9.7128 44.8947 10.3261 44.8947 11.127C44.8947 11.9279 45.0919 12.5464 45.4864 12.9823C45.8909 13.4081 46.3966 13.621 47.0035 13.621Z" fill="#297BCA" />
    <path d="M52.3999 11.0966C52.3999 10.245 52.5668 9.48977 52.9006 8.8308C53.2445 8.17184 53.7097 7.66494 54.2964 7.31011C54.883 6.95529 55.5354 6.77787 56.2535 6.77787C56.7997 6.77787 57.3205 6.89953 57.8162 7.14284C58.3118 7.37601 58.7062 7.69029 58.9995 8.08566V4.08625H61.1539V15.3394H58.9995V14.0924C58.7365 14.508 58.3674 14.8426 57.892 15.096C57.4166 15.3495 56.8654 15.4762 56.2383 15.4762C55.5303 15.4762 54.883 15.2937 54.2964 14.9288C53.7097 14.5638 53.2445 14.0518 52.9006 13.3929C52.5668 12.7238 52.3999 11.9584 52.3999 11.0966ZM59.0147 11.127C59.0147 10.61 58.9136 10.169 58.7113 9.80404C58.509 9.42894 58.2359 9.14508 57.892 8.95246C57.5481 8.7497 57.1789 8.64832 56.7845 8.64832C56.39 8.64832 56.0259 8.74463 55.6921 8.93725C55.3584 9.12987 55.0853 9.41373 54.8729 9.78884C54.6706 10.1538 54.5694 10.5897 54.5694 11.0966C54.5694 11.6035 54.6706 12.0496 54.8729 12.4348C55.0853 12.8099 55.3584 13.0989 55.6921 13.3016C56.036 13.5044 56.4001 13.6058 56.7845 13.6058C57.1789 13.6058 57.5481 13.5095 57.892 13.3168C58.2359 13.1141 58.509 12.8302 58.7113 12.4653C58.9136 12.0901 59.0147 11.6441 59.0147 11.127Z" fill="#297BCA" />
    <path d="M66.4447 15.4762C65.7569 15.4762 65.1399 15.3546 64.5938 15.1113C64.0476 14.8578 63.6127 14.5182 63.289 14.0924C62.9755 13.6666 62.8035 13.1952 62.7732 12.6781H64.9124C64.9528 13.0026 65.1096 13.2712 65.3827 13.4841C65.6659 13.697 66.0148 13.8035 66.4295 13.8035C66.8341 13.8035 67.1476 13.7224 67.3701 13.5601C67.6028 13.3979 67.7191 13.1901 67.7191 12.9367C67.7191 12.6629 67.5775 12.4602 67.2943 12.3284C67.0212 12.1865 66.5812 12.0344 65.9744 11.8722C65.3473 11.7201 64.8315 11.563 64.4269 11.4008C64.0324 11.2386 63.6885 10.9902 63.3952 10.6556C63.112 10.3211 62.9704 9.86994 62.9704 9.30222C62.9704 8.83587 63.1019 8.41008 63.3649 8.02484C63.638 7.6396 64.0223 7.33546 64.5179 7.11242C65.0236 6.88939 65.6153 6.77787 66.293 6.77787C67.2943 6.77787 68.0933 7.03132 68.6901 7.53822C69.2868 8.03498 69.6155 8.70915 69.6762 9.56073H67.6432C67.6129 9.22618 67.4713 8.9626 67.2184 8.76998C66.9757 8.56722 66.647 8.46584 66.2323 8.46584C65.848 8.46584 65.5496 8.5368 65.3372 8.67873C65.1349 8.82066 65.0337 9.01835 65.0337 9.2718C65.0337 9.55566 65.1753 9.77363 65.4585 9.9257C65.7417 10.0676 66.1817 10.2146 66.7785 10.3667C67.3853 10.5188 67.886 10.6759 68.2804 10.8381C68.6749 11.0003 69.0137 11.2538 69.2969 11.5985C69.5902 11.933 69.742 12.3791 69.7521 12.9367C69.7521 13.4233 69.6155 13.8592 69.3424 14.2445C69.0795 14.6297 68.6951 14.9338 68.1894 15.1569C67.6938 15.3698 67.1122 15.4762 66.4447 15.4762Z" fill="#297BCA" />
    <path d="M73.6196 8.13129C73.8927 7.74605 74.2669 7.4267 74.7423 7.17325C75.2278 6.90967 75.779 6.77787 76.396 6.77787C77.1141 6.77787 77.7614 6.95529 78.3379 7.31011C78.9246 7.66494 79.3848 8.17184 79.7186 8.8308C80.0624 9.47963 80.2344 10.2349 80.2344 11.0966C80.2344 11.9584 80.0624 12.7238 79.7186 13.3929C79.3848 14.0518 78.9246 14.5638 78.3379 14.9288C77.7614 15.2937 77.1141 15.4762 76.396 15.4762C75.779 15.4762 75.2328 15.3495 74.7575 15.096C74.2922 14.8426 73.9129 14.5233 73.6196 14.138V19.354H71.4956V6.91473H73.6196V8.13129ZM78.0649 11.0966C78.0649 10.5897 77.9587 10.1538 77.7463 9.78884C77.544 9.41373 77.2709 9.12987 76.927 8.93725C76.5932 8.74463 76.2291 8.64832 75.8347 8.64832C75.4503 8.64832 75.0862 8.7497 74.7423 8.95246C74.4085 9.14508 74.1354 9.42894 73.923 9.80404C73.7208 10.1791 73.6196 10.6201 73.6196 11.127C73.6196 11.6339 73.7208 12.0749 73.923 12.45C74.1354 12.8251 74.4085 13.1141 74.7423 13.3168C75.0862 13.5095 75.4503 13.6058 75.8347 13.6058C76.2291 13.6058 76.5932 13.5044 76.927 13.3016C77.2709 13.0989 77.544 12.8099 77.7463 12.4348C77.9587 12.0597 78.0649 11.6137 78.0649 11.0966Z" fill="#297BCA" />
    <path d="M81.2317 11.0966C81.2317 10.245 81.3986 9.48977 81.7323 8.8308C82.0762 8.17184 82.5364 7.66494 83.1129 7.31011C83.6996 6.95529 84.3519 6.77787 85.07 6.77787C85.6971 6.77787 86.2433 6.9046 86.7086 7.15804C87.184 7.41149 87.5632 7.73084 87.8464 8.11608V6.91473H89.9856V15.3394H87.8464V14.1076C87.5733 14.503 87.1941 14.8325 86.7086 15.096C86.2332 15.3495 85.682 15.4762 85.0549 15.4762C84.3469 15.4762 83.6996 15.2937 83.1129 14.9288C82.5364 14.5638 82.0762 14.0518 81.7323 13.3929C81.3986 12.7238 81.2317 11.9584 81.2317 11.0966ZM87.8464 11.127C87.8464 10.61 87.7453 10.169 87.543 9.80404C87.3407 9.42894 87.0676 9.14508 86.7237 8.95246C86.3799 8.7497 86.0107 8.64832 85.6162 8.64832C85.2218 8.64832 84.8577 8.74463 84.5239 8.93725C84.1901 9.12987 83.917 9.41373 83.7046 9.78884C83.5023 10.1538 83.4012 10.5897 83.4012 11.0966C83.4012 11.6035 83.5023 12.0496 83.7046 12.4348C83.917 12.8099 84.1901 13.0989 84.5239 13.3016C84.8678 13.5044 85.2319 13.6058 85.6162 13.6058C86.0107 13.6058 86.3799 13.5095 86.7237 13.3168C87.0676 13.1141 87.3407 12.8302 87.543 12.4653C87.7453 12.0901 87.8464 11.6441 87.8464 11.127Z" fill="#297BCA" />
    <path d="M91.5139 11.127C91.5139 10.2552 91.6909 9.49484 92.0449 8.84601C92.3989 8.18705 92.8895 7.68015 93.5166 7.32532C94.1436 6.96035 94.8618 6.77787 95.6709 6.77787C96.7127 6.77787 97.5724 7.04146 98.2501 7.56863C98.9378 8.08566 99.398 8.8156 99.6307 9.75842H97.3398C97.2184 9.39346 97.0111 9.1096 96.7177 8.90684C96.4345 8.69394 96.0805 8.58749 95.6557 8.58749C95.0489 8.58749 94.5684 8.81053 94.2144 9.2566C93.8604 9.69253 93.6834 10.316 93.6834 11.127C93.6834 11.9279 93.8604 12.5514 94.2144 12.9975C94.5684 13.4334 95.0489 13.6514 95.6557 13.6514C96.5154 13.6514 97.0768 13.2661 97.3398 12.4957H99.6307C99.398 13.4081 98.9378 14.1329 98.2501 14.6702C97.5623 15.2076 96.7026 15.4762 95.6709 15.4762C94.8618 15.4762 94.1436 15.2988 93.5166 14.944C92.8895 14.579 92.3989 14.0721 92.0449 13.4233C91.6909 12.7643 91.5139 11.9989 91.5139 11.127Z" fill="#297BCA" />
    <path d="M109 10.9446C109 11.2487 108.98 11.5224 108.939 11.7657H102.795C102.845 12.374 103.058 12.8505 103.432 13.1952C103.806 13.5399 104.266 13.7122 104.813 13.7122C105.602 13.7122 106.163 13.3726 106.497 12.6934H108.788C108.545 13.5044 108.08 14.1735 107.392 14.7007C106.704 15.2177 105.86 15.4762 104.858 15.4762C104.049 15.4762 103.321 15.2988 102.673 14.944C102.036 14.579 101.536 14.067 101.172 13.4081C100.818 12.7491 100.641 11.9888 100.641 11.127C100.641 10.2552 100.818 9.48977 101.172 8.8308C101.526 8.17184 102.021 7.66494 102.658 7.31011C103.296 6.95529 104.029 6.77787 104.858 6.77787C105.657 6.77787 106.37 6.95022 106.997 7.29491C107.635 7.6396 108.125 8.13129 108.469 8.76998C108.823 9.39853 109 10.1234 109 10.9446ZM106.8 10.3363C106.79 9.78884 106.593 9.35291 106.208 9.02849C105.824 8.69394 105.354 8.52667 104.797 8.52667C104.272 8.52667 103.827 8.68887 103.462 9.01329C103.108 9.32756 102.891 9.76856 102.81 10.3363H106.8Z" fill="#297BCA" />
  </svg>

)


const SearchIcon = () => (
  <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="37" height="37" rx="18.5" fill="#2369AC"/>
  <path d="M17.8333 23.1667C20.7789 23.1667 23.1667 20.7789 23.1667 17.8333C23.1667 14.8878 20.7789 12.5 17.8333 12.5C14.8878 12.5 12.5 14.8878 12.5 17.8333C12.5 20.7789 14.8878 23.1667 17.8333 23.1667Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M24.5 24.5L21.6 21.6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  
  
)