import React from "react";
import styles from "./Loader.module.scss";

export default function Loader({ reverse, color, size }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: reverse ? "flex-end" : "flex-start"
      }}
    >
      <div
        className={`${styles.ldsEllipsis} ${
          size === "sm" ? styles.ldsEllipsisSm : ""
        }`}
      >
        <div style={{ backgroundColor: color }} />
        <div style={{ backgroundColor: color }} />
        <div style={{ backgroundColor: color }} />
        <div style={{ backgroundColor: color }} />
      </div>
    </div>
  );
}
