export const status = {
    COMPLETED: "COMPLETED",
    PENDING: "PENDING",
    ONGOING: "ONGOING",
    INCOMPLETE: "INCOMPLETE"
}

export const cheating = {
    DETECTED: true,
    NOTDETECTED: false
}

export const TabTypes = {
    VIEW_CANDIDATES: "View Candidates",
    INVITE_CANDIDATES: "Invite Candidates"
}

export const InviteTypes = {
    SINGLE_INVITE: "Single Invite",
    UPLOAD_CV: "Upload CV"
}