import React from 'react';
import styles from './styles.module.scss';

const Step5_V2 = () => (
    <div className={styles.step5CopyLink}>
        <h3>Interview has been created Successfully!!</h3>
        <label><span><CopyIcon /></span> Copy Interview Link</label>
    </div>
)

export default Step5_V2


const CopyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.83301 7.77838C1.83301 7.17723 2.07182 6.60069 2.4969 6.17561C2.92198 5.75053 3.49852 5.51172 4.09967 5.51172H5.56967V6.84505H4.09967C3.85214 6.84505 3.61474 6.94339 3.43971 7.11842C3.26467 7.29345 3.16634 7.53085 3.16634 7.77838V12.5784C3.16634 12.8259 3.26467 13.0633 3.43971 13.2384C3.61474 13.4134 3.85214 13.5117 4.09967 13.5117H8.89967C9.14721 13.5117 9.38461 13.4134 9.55964 13.2384C9.73467 13.0633 9.83301 12.8259 9.83301 12.5784V11.3784H11.1663V12.5784C11.1663 13.1795 10.9275 13.7561 10.5024 14.1812C10.0774 14.6062 9.50083 14.8451 8.89967 14.8451H4.09967C3.49852 14.8451 2.92198 14.6062 2.4969 14.1812C2.07182 13.7561 1.83301 13.1795 1.83301 12.5784V7.77838Z" fill="#2369AC"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.16667 2H12.5C13.2072 2 13.8855 2.28095 14.3856 2.78105C14.8857 3.28115 15.1667 3.95942 15.1667 4.66667V10C15.1667 10.7072 14.8857 11.3855 14.3856 11.8856C13.8855 12.3857 13.2072 12.6667 12.5 12.6667H7.16667C6.45942 12.6667 5.78115 12.3857 5.28105 11.8856C4.78095 11.3855 4.5 10.7072 4.5 10V4.66667C4.5 3.95942 4.78095 3.28115 5.28105 2.78105C5.78115 2.28095 6.45942 2 7.16667 2ZM7.16667 3.33333C6.81304 3.33333 6.47391 3.47381 6.22386 3.72386C5.97381 3.97391 5.83333 4.31304 5.83333 4.66667V10C5.83333 10.3536 5.97381 10.6928 6.22386 10.9428C6.47391 11.1929 6.81304 11.3333 7.16667 11.3333H12.5C12.8536 11.3333 13.1928 11.1929 13.4428 10.9428C13.6929 10.6928 13.8333 10.3536 13.8333 10V4.66667C13.8333 4.31304 13.6929 3.97391 13.4428 3.72386C13.1928 3.47381 12.8536 3.33333 12.5 3.33333H7.16667Z" fill="#2369AC"/>
    </svg>
)
