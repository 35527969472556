import { showToastMessage } from '@/components/ToastComponent';
import { mainAxiosInstance } from '@/services/networkAdapters/axiosInstance';
import React, { useState } from 'react';

const UploadCSV = ({styles, id, toggleTab }) => {
    const [file, setFile] = useState(null);
    const [response, setResponse] = useState({});

    const handleUpload = (e) => {
        setFile(e.target.files[0]);
        setResponse({});
        console.log(e.target.files[0]);
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('offerId', id);

        mainAxiosInstance
            .post(`/auto-recruiter/upload-user-data-csv`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            })
            .then((res) => {
                setResponse(res.data?.data || {});
            })
            .catch((err) => {
                console.log(err);
                showToastMessage("error", err?.response?.data?.message);
            });
    }

    const handleFileDownload = () => {
        // Create an anchor element
        const anchor = document.createElement("a");
        anchor.href = "/SampleCSV.csv"; // Use the imported file path
        anchor.download = 'SampleCSV.csv'; // Set the desired file name for download
        
        // Programmatically click the anchor to trigger the download
        document.body.appendChild(anchor); // Append to the DOM for compatibility
        anchor.click();
        document.body.removeChild(anchor); // Remove after triggering the click
    };

    const sendInviteCandidateRequest = () => {
        if(!file) {
            showToastMessage("error", "No File Chosen!!!");
            return;
        } 
        mainAxiosInstance
            .post("/auto-recruiter/invite-candidates", {
                offerId: id,
                csvDataHash: response.hash
            })
            .then((res) => {
                showToastMessage("info", res.data.message);
                setFile(null);
                setResponse({});
                toggleTab();
            })
            .catch((err) => {
                showToastMessage("error", err.response.data.message);
            });
    }
    
    return (
        <div className={styles.mainInviteArea}>
            <div className={styles.majorSec}>
                <div className={styles.uploadedUserInfos}>
                    <div className={styles.UploadCSV}>
                        <div>
                            <label><UploadSVG /> Upload CSV of candidate to invite</label>
                        </div>
                        <div className={styles.dividerBar} />
                        <div>
                            <button onClick={handleFileDownload}>Download format</button>
                        </div>
                    </div>
                    <div className={styles.uploadInfo}>
                        <div className={styles.uploadCSVNote}>Note: Ensure your CSV includes <span>name, email, and phone</span> in that order (Max: 100 Candidates).</div>
                        <div className={styles.uploadVal}>
                            <h4>Upload File</h4>
                            <div className={styles.uploadButton}>
                                <input onChange={handleUpload} id='CVUpload' type='file' accept=".csv" hidden />
                                <div>
                                    <div>Select files (5MB Max, CSV only):</div>
                                    <label htmlFor='CVUpload'>Choose file</label>
                                    <div>{file?.name || "No file chosen"}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.sendDetails}>
                            <h3>You’ve selected <span>{response?.numberOfUsers || 0}</span> candidates.</h3>
                            <div onClick={sendInviteCandidateRequest}>{file ? "Send Invite" : "Continue"}</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
export default UploadCSV;

const UploadSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
        <path d="M8.5 2V11M3.5 11H1.5C1.23478 11 0.98043 11.1054 0.792893 11.2929C0.605357 11.4804 0.5 11.7348 0.5 12V16C0.5 16.2652 0.605357 16.5196 0.792893 16.7071C0.98043 16.8946 1.23478 17 1.5 17H15.5C15.7652 17 16.0196 16.8946 16.2071 16.7071C16.3946 16.5196 16.5 16.2652 16.5 16V12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H13.5M4.5 6L8.5 1L12.5 6M13.5 14H13.51" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);