/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom'; // Import hooks for routing
import Navbar from '@/dashboards/Navbar';
import ToggleNavbar from "@/dashboards/Navbar/ToggleNavbar/ToggleNavbar";
import { interviewAxiosInstance, mainAxiosInstance } from '@/services/networkAdapters/axiosInstance';
import { showToastMessage } from '@/components/ToastComponent';
import Loader from '@/components/LoaderLg';
import styles from './styles.module.scss';

import Step1 from './components/Steps/Step1/Step1';
import Step2 from './components/Steps/Step2/Step2';
import Step3 from './components/Steps/Step3/Step3';
import Step4 from './components/Steps/Step4/Step4';
import Step5_V2 from './components/Steps/Step5_V2/Step5_V2';
import BackIconSVG from './assets/svg/backIcon.svg';


const AiInterviewBuilder = () => {
    const { id } = useParams(); // Get step ID from the route
    const history = useHistory(); // For navigation

    const [currentStep, setCurrentStep] = useState((Number(id) - 1) > 4 ? 0 : (Number(id) - 1) || 0);
    const staticConfig = useSelector((state) => state.staticConfig);

    const [offerId, setOfferId] = useState(null);

    // Lifted states for Step2
    const [designationData, setDesignationData] = useState("");
    const [aiRecommendedSkills, setAiRecommendedSkills] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState([]);
    const [aiRecommendationsFetched, setAiRecommendationsFetched] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Step3
    const [questions, setQuestions] = useState([{
        id: `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`,
        question: "",
        edited: false,
        isRequired: false,
    }]);

    // Step4
    const [step4Data, setStep4Data] = useState({
        numberOfCandidates: "3",
        interviewTypes: {}
    });
    const [cost, setCost] = useState(0);
    const [defaultField, setDefaultField] = useState(null);
    const [staticDone, setStaticDone] = useState(false);
    const [directStep4, setDirectStep4] = useState(false);

    // Step5
    const [candidatesDetails, setCandidatesDetails] = useState([
        { id: `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`, fullName: "", email: "", phone: "" },
    ]);

    const [loading, setLoading] = useState(false);

    const getCost = () => {
        const data = {
            offerId,
            noOfCandidates: parseInt(step4Data.numberOfCandidates, 10),
            interviewTypes: [],
        }

        Object.keys(step4Data.interviewTypes).forEach((a) => {
            if(step4Data.interviewTypes[a].checked) {
                data.interviewTypes.push(step4Data.interviewTypes[a].id);
            }
        })

        interviewAxiosInstance
            .post("link/cost", data)
                .then((res) => {
                    // setLoading(false);
                    // console.log(res.data);
                    setCost(res.data.data.cost);
                    // setGcoins(res.data.data.gcoins);
                });
    };

    const goToStep = (step) => {
        setCurrentStep(step);
        history.push(`/hirer/create-interview/${step + 1}`); // Update the route to reflect the step
    };

    const goToNextStep = () => {
        const nextStep = currentStep + 1;

        if (nextStep < steps.length) {
            goToStep(nextStep);
        }
    };

    const goToPreviousStep = () => {
        const prevStep = currentStep - 1;
        if (prevStep >= 0) {
            goToStep(prevStep);
        }
    };

    const handleSendJobDetails = () => {
        if (offerId) {
            goToNextStep();
            return;
        }

        const mappedQuestion = questions.filter((a) => a.question !== "").map((a) => ({ que: a.question, isRequired: a.isRequired }));

        setLoading(true);
        mainAxiosInstance
            .post(`/offer/free-flow/create-interview`, {
                designationId: designationData.id,
                title: designationData.value,
                skill: selectedSkill.map((skill) => skill.id),
                screeningQuestion: mappedQuestion,
            })
            .then((res) => {
                setOfferId(res.data.offerId);
                goToNextStep();
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                showToastMessage("error", "Some Error Occured. Please Try Again!!!");
            });
    };

    const steps = [
        { component: Step1, props: { goToNextStep, goToPreviousStep } },
        {
            component: Step2,
            props: {
                goToNextStep,
                goToPreviousStep,
                designationData,
                setDesignationData,
                aiRecommendedSkills,
                setAiRecommendedSkills,
                selectedSkill,
                setSelectedSkill,
                setFetchRecommendations: setAiRecommendationsFetched,
                aiRecommendationsFetched,
            },
        },
        { component: Step3, props: { goToNextStep, goToPreviousStep, questions, setQuestions, handleSendJobDetails } },
        { component: Step4, props: { goToNextStep, goToPreviousStep, step4Data, setStep4Data, cost, defaultField, offerId, getCost, staticDone } },
        { component: Step5_V2, props: {} },
    ];

    useEffect(() => {
        if(id < 4) {
            if(queryParams.get("offerId")) {
                const offer_id = queryParams.get("offerId");
                // setOfferId(offer_id);
                mainAxiosInstance
                    .get(`/offer/owner_preview?offerId=${offer_id}`)
                    .then((res) => {
                        const offerDetails = res?.data?.data[0] || {};
                        // console.log(res);
                        setDesignationData({ id: offerDetails?.designation_id, value: offerDetails?.designation, label: offerDetails?.designation } || "");
                        setSelectedSkill(offerDetails?.skills?.map((a) => ({
                            id: a.skill_id,
                            value: a.skill
                        })) || []);
                        setQuestions(offerDetails?.screeningQuestion?.map((a) => ({
                            id: `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`,
                            question: a.que,
                            edited: true,
                            isRequired: a.isRequired
                        })) || [{
                            id: `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`,
                            question: "",
                            edited: false,
                            isRequired: false,
                        }]);
                    })
                    .catch(() => {
                        showToastMessage("error", "Error While Fetching Details. Try Again!!!");
                        history.push("/hirer/interview-dashboard");
                    })
            } else {
                goToStep(0);
            }
        }
        if(id == 4) {
            if(queryParams.get("offerId")) {
                const offer_id = queryParams.get("offerId");
                setOfferId(offer_id);
                mainAxiosInstance
                    .get(`/offer/particularInterview/${offer_id}`)
                    .then((res) => {
                        if(res.data?.data?.offerDetails?.linkStatus === "ACTIVE") {
                            showToastMessage("error", "Interview is already active!!!");
                            history.push("/hirer/interview-dashboard");
                        } else {
                            setDirectStep4(true);
                        }
                    })
                    .catch((err) => {
                        showToastMessage("error", err?.response?.data?.message);
                        history.push("/hirer/interview-dashboard");
                    })
            } else {
                goToStep(0);
            }
        }
    }, []);

    // Synchronize state with route parameter
    // useEffect(() => {
    //     const stepIndex = Number(id) - 1;
    //     if (stepIndex >= 0 && stepIndex < steps.length) {
    //         setCurrentStep(stepIndex);
    //     } else {
    //         history.push('/hirer/create-interview/1'); // Redirect to step 1 if invalid step
    //     }
    // }, [id]);

    useEffect(() => {
        console.log("StATIC cONFIG", staticConfig);
        if (staticConfig.loaded) {
            setStaticDone(true);
            staticConfig.interviewTypes.forEach((a) => {
                if (a.is_default === 1) setDefaultField(a.type_name);

                setStep4Data((prev) => ({
                    ...prev,
                    interviewTypes: {
                        ...prev.interviewTypes,
                        [a.type_name]: {
                            id: a.id,
                            checked: !!a.is_default,
                            addon_cost: a.addon_cost,
                        },
                    },
                }));
            });
        }
    }, [staticConfig]);

    const CurrentStep = steps[currentStep].component;
    const CurrentProps = steps[currentStep].props;

    const handleCutButton = () => {
        history.push("/hirer/interview-dashboard");
    }

    if(loading) return <Loader />

    return (
        <div>
            <Navbar text="Manage Interviews" showNewUserModeSwitch />
            <div className={`${styles.container}`}>
                <div>
                    <ToggleNavbar isExpanded />
                </div>
                <div className={`${styles.left}`}>
                    <div className={styles.AiInterviewerModal}>
                        { id > 1 && (
                            <div className={styles.topNaviagtor}>
                                { id == 4 ? (
                                    <div />
                                ) : (
                                    <div onClick={goToPreviousStep} className={styles.backSVGDiv}>
                                        <img src={BackIconSVG} alt="Previous" />
                                    </div>
                                )}
                                <div className={styles.steps}>
                                    {["", "", ""].map((a, i) => (
                                        <div className={i+1 < id && styles.active} />
                                    ))}
                                </div>
                                <div onClick={handleCutButton} className={styles.backSVGDiv}>
                                    <CrossIcon />
                                </div>
                            </div>
                        )}
                        {/* Render the current step with props */}
                        <CurrentStep {...CurrentProps} />
                        {currentStep === 3 && (
                            <div className={styles.NoteVal}>
                                <em>*Note: The amount would be deducted from your Gcoins Wallet!!</em>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AiInterviewBuilder;

const CrossIcon = () => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 1.41L7.41 6L12 10.59L10.59 12L6 7.41L1.41 12L0 10.59L4.59 6L0 1.41L1.41 0L6 4.59L10.59 0L12 1.41Z"
            fill="black"
            fillOpacity="0.6"
        />
    </svg>
);