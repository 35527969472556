import React from 'react'
import styles from '../styles.module.scss';
import step1Styles from './styles.module.scss';

// SVGS
import LiveVideoInterviewSVG from '../../../assets/svg/LiveVideoInterview.svg';
import CheatDetectionSVG from '../../../assets/svg/CheatDetection.svg';
import AIPoweredScreeningSVG from '../../../assets/svg/AIPoweredScreening.svg';
import DetailedActionableInsightsSVG from '../../../assets/svg/DetailedActionableInsights.svg';

const Step1 = ({ goToNextStep }) => {

    const Step1Data = [
        { title: "Live Video Interviews", desc: "Conducts live video interviews, evaluating a candidate’s qualifications, experience, and skills for a complete profile.", icon: LiveVideoInterviewSVG },
        { title: "AI-Powered Screening with Domain Expertise", desc: "Delivers industry-specific assessments across IT, healthcare, finance, and more for accurate evaluations.", icon: AIPoweredScreeningSVG },
        { title: "Cheat Detection", desc: "Detects use of external tools, like ChatGPT, during interviews to ensure genuine assessments.", icon: CheatDetectionSVG },
        { title: "Detailed, Actionable Insights", desc: "Provides comprehensive reports with insights on candidate fit and qualifications, supporting informed hiring decisions.", icon: DetailedActionableInsightsSVG }
    ]

    return (
        <div className={`${styles.mainContainer} ${step1Styles.mainCont}`}>
            <div className={step1Styles.step1Div}>
                <div className={step1Styles.header}>
                    <h1>Goodspace AI Interviewer</h1>
                    <div>Set up an interview and get detailed candidate insights for confident hiring.</div>
                </div>
                <div className={step1Styles.IntroInfoGrp}>
                    { Step1Data.map((data) => (
                        <div key={data.title} className={step1Styles.IntroInfo}>
                            <h4><img src={data.icon} alt={data.title} /> <div>{data.title}</div></h4>
                            <div>{data.desc}</div>
                        </div>
                    ))}
                </div>
                <div className={step1Styles.buttonGrp}>
                    <button type='button' onClick={goToNextStep}>Create a interview</button>
                </div>
            </div>
        </div>
    )
}

export default Step1