import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles.module.scss';
import step3Styles from './styles.module.scss';

// SVG's
import BackIconSVG from '../../../assets/svg/backIcon.svg';
import DragAndDropFunc from '../../DragAndDropFunc/DragAndDropFunc';
import AiInterviewBuilderSVG from '../../../assets/svg/AIInterviewBuilder.svg';

const Step3 = ({ goToPreviousStep, questions, setQuestions, handleSendJobDetails }) => {

    const [AISuggestedQuestions, setAISuggestedQuestions] = useState([
        { title: "Background check", ques: "Are you willing to undergo a background check, in accordance with local law/regulations?" },
        { title: "Education", ques: "Have you completed the following level of education: Bachelor's Degree?" },
        { title: "Expert with skill", ques: "How many years of work experience do you have with [Skill]?" },
        { title: "Hybrid work", ques: "Are you comfortable working in a hybrid setting?" },
        { title: "Industry Experience", ques: "How many years of [Industry] experience do you currently have?" },
        { title: "Language", ques: "What is your level of proficiency in [Language]?" },
        { title: "Location", ques: "Are you comfortable commuting to this job's location?" },
        { title: "Expected salary", ques: "Is your expected salary is between [Range] LPA?" },
        { title: "Notice period", ques: "We must fill this position urgently. Can you start immediately?" }
    ]);

    const [hideSuggestions, setHideSuggestions] = useState(false);
    const dragAndDropRef = useRef(null);

    // Function to add a new question if the user types in the last question
    const addNewQuestionIfNeeded = () => {
        if (questions.length < 4) {
            setQuestions((prev) => [
                ...prev,
                {
                    id: `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`,
                    question: "",
                    isRequired: false,
                },
            ]);
        }
    };

    // Function to handle changes in each question's input field
    const handleInputChange = (id, value) => {
        setQuestions((prev) =>
            prev.map((q) =>
                q.id === id ? { ...q, question: value, edited: value.length > 0 } : q
            )
        );

        // Check if the modified question is the last one and then add a new question
        const isLastQuestion = questions[questions.length - 1].id === id;
        if (isLastQuestion && value.trim() !== "") {
            addNewQuestionIfNeeded();
        }
    };

    function debounce(func, delay) {
        let timer;
        return function (...args) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => func.apply(this, args), delay);
        };
    }

    const handleUpdateQuestions = (updatedQuestions) => {
        setQuestions(updatedQuestions);
    };

    const debouncedHandleInputChange = debounce(handleInputChange, 800);

    const handleAddQuestionFromSuggestion = (ques) => {
        const questionId = questions[questions.length - 1].id;
        handleInputChange(questionId, ques.ques);
    
        setAISuggestedQuestions((prev) => prev.filter((suggestion) => suggestion.ques !== ques.ques));
    }

    // Scroll event handler
    const handleScroll = () => {
        if (dragAndDropRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = dragAndDropRef.current;
            setHideSuggestions(scrollTop + clientHeight + 20 >= scrollHeight);
        }
    };

    // Add scroll event listener
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const questionDragersElem = dragAndDropRef.current;
        if (questionDragersElem) {
            questionDragersElem.addEventListener('scroll', handleScroll);
            return () => questionDragersElem.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <div className={`${styles.mainContainer} ${styles.smallContainer}`}>
            <div className={step3Styles.TopHeader}>
                <div onClick={goToPreviousStep} className={step3Styles.backSVGDiv}>
                    {/* <img src={BackIconSVG} alt='Previous' /> */}
                </div>  
                <div>Custom Questions</div>
                <div />
            </div>
            <div className={step3Styles.mainContent}>
                <div className={step3Styles.majorContentDiv}>
                    <div>Screening Questions (you can select a maximum of 4 questions and arrange them as needed.)</div>
                    <div className={step3Styles.questionDragers}>
                        <DragAndDropFunc 
                            ref={dragAndDropRef}
                            questions={questions} 
                            onUpdateQuestions={handleUpdateQuestions}
                            onInputChange={debouncedHandleInputChange} 
                        />
                    </div>
                    {(questions.length < 4 || questions[questions.length - 1].question === "") && 
                        (<div className={step3Styles.AiSuggestedSkills} style={hideSuggestions ? { opacity: '0', visibility: 'hidden' } : {  }}>
                            <h3><img src={AiInterviewBuilderSVG} alt='' />AI Suggested Skills</h3>
                            <div className={step3Styles.AiRecommendedSkillGrp}>
                                {AISuggestedQuestions.map((ques) => (
                                    <div key={ques.title} onClick={() => handleAddQuestionFromSuggestion(ques)}>
                                        <div><AddIcon /></div>
                                        {ques.title.length > 20 ? `${ques.title.slice(0, 20)}...` : ques.title}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className={step3Styles.buttonGrp}>
                    <button type='button' onClick={handleSendJobDetails}>Continue</button>
                </div>
            </div>
        </div>
    );
};

export default Step3;

const AddIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M6.50003 1.75736V10.2426M2.25739 6H10.7427" stroke="#2369AC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)